import React, { useEffect, useState } from "react";

// hooks
import useToggle from "hooks/useToggle";

// constants
const conversationOverview = [
  {
    id: 1,
    label: "All conversations",
    key: "all_conversation",
    color: "#5C3BBF",
  },
  {
    id: 2,
    label: "Marketing",
    key: "marketing",
    color: "#32CDCD",
  },
  {
    id: 3,
    label: "Utility",
    key: "utility",
    color: "#AC2F82",
  },
  {
    id: 4,
    label: "Service",
    key: "service",
    color: "#0ACD95",
  },
  {
    id: 5,
    label: "Free Tier",
    key: "free_tier",
    color: "#6940F2",
  },
];

export default function ConversationOverview() {
  const [isMetrix, setIsMetrix] = useToggle(false);
  const [metrixData, setMetrixData] = useState(null);

  return (
    <div className="w-full h-full min-h-[24vw] max-h-[50%] bg-white rounded-[1vw] py-[1.2vw] overflow-hidden">
      <h1 className="text-[var(--font-600)] font-normal weight-semibold px-[1vw] pb-[1vw]">
        Conversation Overview
      </h1>
      <div className="w-full h-full overflow-hidden pr-[0.5vw] pb-[1vw]">
        <div className="w-full h-auto">
          <div className="px-[1vw]"></div>

          <div className="w-full h-full flex-column px-[0.5vw] mt-[0.6vw]">
            {!isMetrix &&
              conversationOverview?.map((list) => {
                return (
                  <Card
                    key={list?.id}
                    data={list}
                    count={metrixData?.[list?.key]}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

function Card({ data, count }) {
  const { label, color } = data;

  return (
    <div className="w-auto h-[2.8vw] bg-[var(--white)] px-[0.85vw] py-[0.3vw] gap-[0.5vw]] flex items-center justify-between">
      <div className="flex items-center gap-[0.4vw]">
        <div
          className={`contents-[""] size-[0.6vw] rounded-[0.2vw]`}
          style={{
            background: color ?? "#f00",
          }}
        />
        <p className="font-md text-[#4C515B] weight-medium">{label}</p>
      </div>
      <div className="font-normal weight-bold">{count ?? 0}</div>
    </div>
  );
}
