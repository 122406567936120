import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import "../../../../components/commonComponents/editor/editor.css";

// asstes
import { ICONS } from "../../../../assets/icons";
import Avatar from "../../../../assets/customSVG/Avatar";
import Close from "../../../../assets/customSVG/Close";
import DocSvg from "../../../../assets/customSVG/DocSvg";
import ImageSvg from "../../../../assets/customSVG/ImageSvg";
import VideoSvg from "../../../../assets/customSVG/VideoSvg";
import StopSvg from "../../../../assets/customSVG/StopSvg";
import Send from "../../../../assets/customSVG/Send";
import bg from "../../../../assets/images/inbox/conversation_bg.svg";

// utils
import moment from "moment";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import {
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  convertToRaw,
  getDefaultKeyBinding,
} from "draft-js";
import { draftToMarkdown } from "markdown-draft-js";
import { Modal, Popover } from "antd";

// API
import { sendTemplateMessage, sendTextMessage } from "../../api/Api";

// contents
import { ATTACHMENTS_TYPE, INLINE_STYLES } from "../../constants/index";
import {
  FEATURE_FLAG_ADD_TO_STAR,
  FEATURE_FLAG_BLOCKED_STATUS,
  FEATURE_FLAG_CONVERSATION_CLOSE,
  FEATURE_FLAG_CONVERSATION_OPEN,
  FEATURE_FLAG_MANUALLY_CLOSE_CONVERSATION,
  FEATURE_FLAG_SEND_ATTACHMENTS,
  FEATURE_FLAG_SEND_DOCUMENT,
  FEATURE_FLAG_SEND_IMAGE,
  FEATURE_FLAG_SEND_VIDEO,
  FEATURE_FLAG_TEMPLATE_CHANGE_MEDIA,
  FEATURE_FLAG_TEMPLATE_CHANGE_VARIABLE,
} from "../../constants/FeatureFlag";

// hooks
import useToggle from "../../../../hooks/useToggle";

// helper
import { removeDoubleStyles, replaceFormating } from "../../helper/index";
import { uploadFile } from "../../../../helper/uploadFile";
import { capitalizeFirstLetter } from "../../../../helper/capitalizeFirstLetter";
import { dateConverter } from "../../../../helper/dateConverter";

// components
import FileUploader from "../../../../components/commonComponents/fileUploader";
import Loader from "../../../../components/commonComponents/Loader/Index";
import InlineStyleControls from "../../../../components/commonComponents/editor/InlineStyleControls";
import Header from "../../../../components/commonComponents/modals/Header";
import TemplateListContainer from "../../../../components/commonComponents/templates/TemplateListContainer";
import LeftSideConversationCard from "./LeftSideConversationCard";
import RightSideConversationCard from "./RightSideConversationCard";
import ConversationClose from "./ConversationClose";
import ConversationStar from "./ConversationStar";

// redux
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import { cn } from "../../../../helper/cn";

let prevCount = 0;
let fileType = ".jpg, .png";

export default function ConversationContainer(props) {
  const {
    loading,
    secondaryLoading,
    selectedConversationType,
    loadNext,
    selectedPhoneNumber,
    selectedPerson,
    conversation,
    getConversations,
    onConversationStarChange,
    onCloseConversation,
  } = props;

  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const fileInputRef1 = useRef();
  const fileInputRef2 = useRef();
  const fileInputRef3 = useRef();

  const conversationRef = useRef();
  const bottomRef = useRef();

  // editor
  const editorRef = useRef(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showToolbar, setShowToolbar] = useToggle(false);
  const [showEmojiPicker, setShowEmojiPicker] = useToggle(false);
  const [msg, setMsg] = useState("");

  // template
  const [openTemplateList, setOpenTemplateList] = useToggle(false);
  const [isSending, setIsSending] = useToggle(false);

  const [isMsgSending, setIsMsgSending] = useToggle(false);

  // Media files
  const [openAttachment, setOpenAttachment] = useToggle(false);
  const [errorMsg, setErrorMsg] = useToggle(false);
  const [isUploading, setIsUploading] = useToggle(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [updatedData, setUpdatedData] = useState(null);
  const [updateFormat, setUpdateFormat] = useState("text");

  useEffect(() => {
    clearMedia();
    setEditorState(EditorState.createEmpty());
  }, [selectedPerson]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (conversation?.length) prevCount = conversation?.length;
  //   }, 5000);

  //   return () => clearTimeout(timeout);
  // }, [conversation]);

  useEffect(() => {
    // if (prevCount === conversation?.length) scrollingBottom();
    // else
    if (prevCount === 0) scrollingBottom();
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (prevCount === 0) prevCount === conversation?.length;
    }, 10000);

    return () => clearInterval(interval);
  }, [prevCount]);

  // scroll to the last conversation
  const scrollingBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  // editor
  const onEditorChange = (newState) => {
    setEditorState(newState);
    setShowEmojiPicker(false);

    const content = newState?.getCurrentContent();
    const rawObject = convertToRaw(content);
    const markdownString = draftToMarkdown(rawObject);

    setMsg(markdownString);

    if (["image", "video", "document"]?.includes(updateFormat)) {
      setUpdatedData({
        ...updatedData,
        [updatedData?.type]: {
          ...updatedData[updatedData?.type],
          caption: markdownString,
        },
      });
    } else {
      setUpdatedData({
        type: "text",
        text: {
          body: markdownString,
        },
      });
    }

    // setEditorState(markdownString);
  };

  const handleEmojiClick = (emoji) => {
    const contentState = editorState?.getCurrentContent();
    const selection = editorState?.getSelection();

    if (selection.isCollapsed()) {
      const contentStateWithEmoji = contentState.createEntity(
        "emoji",
        "IMMUTABLE",
        {
          emoji: emoji.native,
        }
      );
      const entityKey = contentStateWithEmoji?.getLastCreatedEntityKey();

      const contentStateWithEntity = Modifier.insertText(
        contentStateWithEmoji,
        selection,
        emoji.native,
        null,
        entityKey
      );

      const newEditorStateWithEntity = EditorState.push(
        editorState,
        contentStateWithEntity,
        "insert-fragment"
      );

      setEditorState(newEditorStateWithEntity);
      // setBodyObj({ ...bodyObj, text: newEditorStateWithEntity });
      setShowEmojiPicker(false);
      onEditorChange(newEditorStateWithEntity);
    }
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const mapKeyToEditorCommand = (e) => {
    if (e.key === "b" && e.metaKey) return "bold";

    if (e.key === "i" && e.metaKey) return "italic";

    if (e.key === "s" && e.metaKey) return "strikethrough";

    return getDefaultKeyBinding(e);
  };

  const handleToolbarClick = (style) => {
    const newState = RichUtils.toggleInlineStyle(editorState, style);
    setEditorState(newState);
    // setBodyObj({ ...bodyObj, text: newState });
  };

  // media files
  // handle upload media files
  const handleUploadFiles = async (e, format) => {
    setIsUploading(true);
    setErrorMsg(false);
    if (e?.target?.files?.[0]) {
      const selectedFile = e?.target?.files?.[0];
      const responseFile = await uploadFile(e?.target?.files?.[0]);

      let fileTypes = {
        label: format
          ? format?.toUpperCase()
          : selectedFile?.type?.split("/")?.[0]?.toUpperCase(),
      };

      setUpdatedData({
        ...updatedData,
        type: format,
        [format]: {
          link: responseFile,
          caption: updatedData?.[format]?.caption || "",
        },
      });

      setSelectedFileType(fileTypes);

      if (responseFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedFileURL(responseFile);
          setIsUploading(false);
        };
        setSelectedFile(selectedFile);
        reader.readAsDataURL(selectedFile);
        if (fileTypes?.label === "IMAGE")
          selectedFile.size < 5 * 1024 * 1024
            ? setErrorMsg(false)
            : setErrorMsg(true);

        if (fileTypes?.label === "VIDEO")
          selectedFile.size < 16 * 1024 * 1024
            ? setErrorMsg(false)
            : setErrorMsg(true);

        if (fileTypes?.label === "DOCUMENT")
          selectedFile.size < 100 * 1024 * 1024
            ? setErrorMsg(false)
            : setErrorMsg(true);
      }
    } else {
      setIsUploading(false);
    }
  };

  // remove selected media file
  const clearMedia = () => {
    setSelectedFile(null);
    setSelectedFileType(null);
    setSelectedFileURL(null);
  };

  // template
  const handleCancel = () => {
    setOpenTemplateList(false);
  };

  const sendTemplate = async ({
    sendFrom,
    sendTo,
    metaTemplateId,
    brandId,
    subcategory_type,
    mediaUrl,
    variables,
  }) => {
    setIsSending(true);

    try {
      const res = await sendTemplateMessage({
        sendFrom: sendFrom,
        sendTo: [+sendTo],
        metaTemplateId: metaTemplateId,
        brandId: brandId,
        subcategory_type: subcategory_type,
        variables: { ...variables, ...mediaUrl },
      });

      if (res?.status === 200) {
        setIsSending(false);
        setOpenTemplateList(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Template send successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
        getConversations(
          "select",
          false,
          currentBrand?.brand_id,
          10,
          1,
          selectedPerson?.from_number,
          selectedPerson?.to_number
        );
        scrollingBottom();
      }

      if (res?.status !== 200) {
        setIsSending(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Template send failed",
              status: "Failed",
              duration: "",
            },
          ])
        );
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const sendMessage = async (sendFrom, sendTo, brandId, msg) => {
    setIsMsgSending(true);

    try {
      const res = await sendTextMessage({
        sendFrom: sendFrom,
        sendTo: +sendTo,
        brandId: brandId,
        msg: msg,
      });

      if (res?.status === 200) {
        setIsMsgSending(false);
        getConversations(
          "select",
          false,
          currentBrand?.brand_id,
          10,
          1,
          selectedPerson?.from_number,
          selectedPerson?.to_number
        );
        scrollingBottom();
        setEditorState(EditorState?.createEmpty());
        setUpdatedData(null);
        clearMedia();
      }

      if (res?.status !== 200) {
        setIsMsgSending(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "message send failed",
              status: "Success",
              duration: "",
            },
          ])
        );
      }
    } catch (e) {
      console.warn(e);
    }
  };

  // constants
  const list = [];
  const name =
    selectedPerson?.first_name && selectedPerson?.last_name
      ? `${selectedPerson?.first_name} ${selectedPerson?.last_name}`
      : selectedPerson?.first_name
      ? selectedPerson?.first_name
      : selectedPerson?.last_name
      ? selectedPerson?.last_name
      : selectedPerson?.profile_name && selectedPerson?.profile_name;

  // popover contents
  const atteachmentsContent = (
    <div className="w-44 h-fit p-[0.5vw] flex flex-col gap-1 justify-between">
      {FEATURE_FLAG_SEND_IMAGE && (
        <div className={cn("w-full max-h-12")}>
          <div
            className="w-full flex items-center relative gap-[0.6vw] px-[0.6vw] py-[0.5vw] rounded-[0.37vw] hover:bg-[var(--BG-25)] cursor-pointer"
            onClick={() => {
              fileInputRef1.current.value = null;
              fileInputRef1.current.click();
              setOpenAttachment(false);
              setUpdateFormat("image");
            }}
          >
            <ImageSvg color="var(--contentText)" width={22} height={22} />
            <p>Photos</p>
          </div>
        </div>
      )}
      {FEATURE_FLAG_SEND_VIDEO && (
        <div className={cn("w-full max-h-12")}>
          <div
            className="w-full flex items-center relative gap-[0.6vw] px-[0.6vw] py-[0.5vw] rounded-[0.37vw] hover:bg-[var(--BG-25)] cursor-pointer"
            onClick={() => {
              fileInputRef2.current.value = null;
              fileInputRef2.current.click();
              setOpenAttachment(false);
              setUpdateFormat("video");
            }}
          >
            <VideoSvg color="var(--contentText)" width={22} height={22} />
            <p>video</p>
          </div>
        </div>
      )}
      {FEATURE_FLAG_SEND_DOCUMENT && (
        <div className={cn("w-full max-h-12")}>
          <div
            className="w-full flex items-center relative gap-[0.6vw] px-[0.6vw] py-[0.5vw] rounded-[0.37vw] hover:bg-[var(--BG-25)] cursor-pointer"
            onClick={() => {
              fileInputRef3.current.value = null;
              fileInputRef3.current.click();
              setOpenAttachment(false);
              setUpdateFormat("document");
            }}
          >
            <DocSvg color="var(--contentText)" width={22} height={22} />
            <p>Doc</p>
          </div>
        </div>
      )}
      <input
        type="file"
        accept={".jpg, .png"}
        onChange={(e) => {
          handleUploadFiles(e, "image");
        }}
        id="uploadImg"
        ref={fileInputRef1}
        hidden
      />
      <input
        type="file"
        accept={".mp4"}
        onChange={(e) => {
          handleUploadFiles(e, "video");
        }}
        id="uploadImg"
        ref={fileInputRef2}
        hidden
      />
      <input
        type="file"
        accept={".pdf"}
        onChange={(e) => {
          handleUploadFiles(e, "document");
        }}
        id="uploadImg"
        ref={fileInputRef3}
        hidden
      />
    </div>
  );

  const allowToSendMessage = useCallback(() => {
    return (
      (updatedData?.type === "image" && updatedData?.image?.link?.length > 0) ||
      (updatedData?.type === "video" && updatedData?.video?.link?.length > 0) ||
      (updatedData?.type === "document" &&
        updatedData?.document?.link?.length > 0) ||
      editorState?.getCurrentContent()?.getPlainText()?.trim()?.length > 0
    );
  }, [updatedData, editorState]);

  // styles
  const uploadContainer =
    "h-[3vw] flex items-center gap-[0.3vw] bg-[var(--primary)] px-[0.5vw] py-[0.1.5vw] rounded-[0.5vw] cursor-pointer";
  const error = "text-[var(--fontRed)] font-md weight-400 my-[0.5vw]";

  const lastReplied = dateConverter(selectedPerson?.updated_at, true);

  return (
    <div
      className="w-full h-full p-0"
      style={{
        background: `url(${bg}) no-repeat`,
      }}
    >
      <div className="flex items-center justify-between gap-[0.8vw] p-[1vw] border-b-[0.08vw] border-[var(--border-50)] bg-white shadow-[0px_1.5px_2px_0px_#1018281A] !z-50">
        <div className="flex items-center gap-[0.8vw]">
          <div className="size-[2.5vw] flex items-center justify-center rounded-full bg-gray-200">
            <Avatar color="#ffffff" />
          </div>
          <div className="">
            <p className="font-normal weight-semibold leading-[1vw]">{name}</p>
            <p className="font-xs weight-semibold text-[var(--font-600)]">
              Last replied {lastReplied?.formatedDateWithoutYear} -{" "}
              {`${lastReplied?.formattedHours}:${lastReplied?.minutes}${lastReplied?.meridiem}`}
            </p>
          </div>
        </div>
        {(FEATURE_FLAG_MANUALLY_CLOSE_CONVERSATION ||
          FEATURE_FLAG_ADD_TO_STAR) && (
          <div className="w-fit flex items-center gap-[0.8vw]">
            {FEATURE_FLAG_MANUALLY_CLOSE_CONVERSATION && (
              <ConversationClose
                className={"w-[0.95vw] h-[0.95vw]"}
                onChange={onCloseConversation}
                data={selectedPerson}
              />
            )}
            {FEATURE_FLAG_ADD_TO_STAR && (
              <ConversationStar
                data={selectedPerson}
                width={"0.95vw"}
                height={"0.95vw"}
                onChange={onConversationStarChange}
              />
            )}
          </div>
        )}
      </div>
      {/* Alert msg for no data available to fetch */}
      {/* <div
        className={`w-full h-auto flex items-center justify-center transition-transform ease-linear duration-300 z-20 ${
          alert ? 'translate-y-[1vw]' : '-translate-y-[5vw]'
        }`}
      >
        <p className='px-[0.5vw] py-[0.3vw] bg-[var(--white)] rounded-full'>
          No data Available
        </p>
      </div> */}
      {(loading || secondaryLoading) && (
        <div className={`w-full h-[5vh] flex items-center justify-center`}>
          <Loader Width={30} Height={30} loaderBg="white" />
        </div>
      )}
      <div
        className={`w-full h-full flex flex-col overflow-y-scroll relative ${
          selectedConversationType?.type == "closed"
            ? "!max-h-[58.5vh]"
            : (selectedFile || isUploading) && secondaryLoading
            ? "!max-h-[46.5vh]"
            : loading || secondaryLoading || selectedFile || isUploading
            ? "!max-h-[51.5vh]"
            : "!max-h-[56.5vh]"
        }`}
        ref={conversationRef}
        onScroll={() => {
          if (conversationRef?.current?.scrollTop === 0) {
            loadNext();
            prevCount = conversation?.length;
          }
        }}
      >
        {!loading &&
          conversation?.length > 0 &&
          conversation?.map((c) => {
            const momentDate = moment(c?.send_at);

            const convertedTime =
              momentDate.from(moment()).indexOf("an") !== -1
                ? momentDate.from(moment())?.replace("an", "1")
                : momentDate.from(moment()).indexOf("a ")
                ? momentDate?.format("MMMM DD, YYYY")
                : momentDate.from(moment());

            let showTime = !list?.includes(convertedTime);

            if (showTime) list.push(convertedTime);

            const convertion =
              convertedTime === moment()?.format("MMMM DD, YYYY")
                ? "Today"
                : convertedTime === "a day ago"
                ? "Yesterday"
                : convertedTime === "a few seconds ago"
                ? "now"
                : convertedTime;

            return (
              <div className="flex flex-col last:pb-[2vw]">
                {showTime && (
                  <div
                    key={convertion}
                    className="w-full flex justify-center items-center font-xs weight-medium my-[1vw] text-[var(--font-400)]"
                  >
                    <p className="px-[0.7vw] py-[0.25vw] rounded-full bg-white shadow-[0_1px_8px_#00000016] text-center">
                      {convertion}
                    </p>
                  </div>
                )}
                {FEATURE_FLAG_CONVERSATION_OPEN && (
                  <div
                    key={convertion}
                    className="w-full flex justify-center items-center font-xs weight-medium my-[1vw] text-[var(--contentText)]"
                  >
                    <p className="px-[0.7vw] py-[0.25vw] rounded-full bg-white shadow-[0_1px_8px_#00000016] text-center">
                      Utility Conversation Started
                    </p>
                  </div>
                )}
                {FEATURE_FLAG_CONVERSATION_CLOSE && (
                  <div
                    key={convertion}
                    className="w-full flex justify-center items-center font-xs weight-medium my-[1vw] text-[var(--contentText)]"
                  >
                    <p className="px-[0.7vw] py-[0.25vw] rounded-full bg-white shadow-[0_1px_8px_#00000016] text-center">
                      Utility Conversation Closed
                    </p>
                  </div>
                )}
                {c?.is_customer_msg ? (
                  <div
                    key={c?.id}
                    className="flex justify-start px-[1vw] py-[0.5vw]"
                  >
                    <LeftSideConversationCard data={c} />
                  </div>
                ) : (
                  <div
                    key={c?.id}
                    className="flex justify-end px-[1vw] py-[0.5vw]"
                  >
                    <RightSideConversationCard data={c} />
                  </div>
                )}
                {FEATURE_FLAG_BLOCKED_STATUS && (
                  <div className="w-full flex items-center justify-center">
                    <div className="w-fit bg-[#FDE68A] font-sm weight-medium flex items-center gap-2.5 p-1.5 text-[var(--contentText)] py-2.5">
                      <StopSvg width={18} height={18} />
                      <p>This user has blocked you from sending messages</p>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <div className="" ref={bottomRef}></div>
      </div>
      <div
        className={`w-full h-auto flex items-center justify-center contents-[""] bg-[var(--white)] shadow-[0px_-2px_2px_0px_#1018281A] p-[1vw] ${
          selectedFile || isUploading
            ? "min-h-[25vh]"
            : "min-h-[20vh] max-h-[20vh]"
        }`}
      >
        {selectedConversationType?.type === "closed" ? (
          <div className="w-full h-[13vh] px-[2vw] bg-[var(--BG-25)] text-[var(--white)] flex flex-col items-center justify-center rounded-[0.5vw]">
            <div className="w-full mx-auto font-md weight-medium text-[var(--font-600)] text-center">
              <p>Your open conversation has timed out.</p>
              <p>
                start a conversation using templates by clicking the button
                below.
              </p>
            </div>
            <button
              className="flex items-center justify-center font-md weight-semibold px-[1vw] py-[0.3vw] mt-[0.7vw] bg-[var(--primary)] text-[var(--white)] rounded-[0.4vw]"
              onClick={() => {
                setOpenTemplateList(true);
              }}
              data-testid="closed-template-choose"
            >
              Choose Template
            </button>
          </div>
        ) : (
          <div
            className={`textEditorContainer !p-0 overflow-hidden ${
              selectedFile || isUploading ? "!h-[22vh]" : "!h-[17vh]"
            }`}
          >
            <div
              className={`w-full font-md weight-small px-[1vw] pt-[0.5vw] pb-[0.3vw] overflow-hidden overflow-y-scroll listScroll ${
                selectedFile || isUploading ? "h-[54%]" : "h-[79%]"
              }`}
              onDoubleClick={() => setShowToolbar(!showToolbar)}
            >
              <Editor
                className="!break-words"
                ref={editorRef}
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                keyBindingFn={mapKeyToEditorCommand}
                onChange={onEditorChange}
                placeholder={`Write reply . . .`}
                handleBeforeInput={(val) => {
                  const textLength = editorState
                    .getCurrentContent()
                    .getPlainText().length;
                  if (val && textLength >= 1024) {
                    return "handled";
                  }
                  return "not-handled";
                }}
                handlePastedText={(val) => {
                  const textLength = editorState
                    ?.getCurrentContent()
                    ?.getPlainText()?.length;
                  return (val?.length + textLength ?? 0) >= 1025;
                }}
              />
            </div>

            {(selectedFile || isUploading) && (
              <div className="">
                <div className="flex items-center gap-[0.8vw] px-[1vw]">
                  {isUploading ? (
                    <div className="w-full h-[6.5vh] flex items-center">
                      <Loader Width={30} Height={30} loaderBg="white" />
                    </div>
                  ) : (
                    selectedFile && (
                      <div className="w-24 h-[6.5vh] flex items-center gap-[1vw]">
                        <div className="relative">
                          <div
                            className="absolute top-[0.1vw] right-[0.1vw] cursor-pointer"
                            onClick={() => {
                              clearMedia();
                            }}
                          >
                            <Close
                              width="1.3vw"
                              height="1.3vw"
                              fill="#616874"
                              stroke="#FFFFFF"
                            />
                          </div>
                          <div>
                            {selectedFileType?.label === "IMAGE" &&
                              selectedFileURL && (
                                <img
                                  src={selectedFileURL}
                                  alt="preview images"
                                  className="size-[3vw] font-xs weight-medium rounded-[0.5vw]"
                                />
                              )}
                            {selectedFileType?.label === "VIDEO" &&
                              selectedFileURL && (
                                <div className={`${uploadContainer}`}>
                                  <VideoSvg
                                    color="var(--white)"
                                    width={"2.3vw"}
                                    height={"2.3vw"}
                                  />
                                </div>
                              )}
                            {selectedFileType?.label === "DOCUMENT" &&
                              selectedFileURL && (
                                <div className={`${uploadContainer}`}>
                                  <DocSvg
                                    color="#fff"
                                    width={"1.5vw"}
                                    height={"1.5vw"}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                        {errorMsg && (
                          <>
                            {selectedFileType?.label === "IMAGE" &&
                              errorMsg && (
                                <div className={`${error}`}>
                                  Image size should be less than 5MB
                                </div>
                              )}

                            {selectedFileType?.label === "VIDEO" &&
                              errorMsg && (
                                <div className={`${error}`}>
                                  Video size should be less than 16MB
                                </div>
                              )}

                            {selectedFileType?.label === "DOCUMENT" &&
                              errorMsg && (
                                <div className={`${error}`}>
                                  Document size should be less than 100MB
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}

            <div className="flex items-center justify-between h-fit px-[0.5vw] bg-[var(--white)] rounded-b-[0.7vw]">
              <div className="flex items-center">
                <div className="w-auto h-fit flex items-center gap-[0.5vw]">
                  <button className="size-[1.5vw] hover:bg-slate-100 flex items-center justify-center rounded-[0.4vw] cursor-not-allowed">
                    <img
                      src={ICONS?.StarGroup}
                      alt=""
                      className="size-[0.8vw]"
                    />
                  </button>
                  <button
                    className="size-[1.5vw] hover:bg-slate-100 flex items-center justify-center rounded-[0.4vw]"
                    onClick={() => {
                      setOpenTemplateList(true);
                    }}
                    data-testid="selectTemplate"
                  >
                    <img
                      src={ICONS?.SelectTemplate}
                      alt=""
                      className="size-[0.9vw]"
                    />
                  </button>
                </div>
                <div className="w-auto h-fit flex items-center gap-[0.5vw] border-x-[0.08vw] border-[var(--border-50)] px-[1vw] mx-[0.8vw]">
                  <InlineStyleControls
                    options={INLINE_STYLES}
                    editorState={editorState}
                    onToggle={handleToolbarClick}
                  />
                </div>
                <div className="w-auto h-fit flex items-center gap-[0.5vw]">
                  {FEATURE_FLAG_SEND_ATTACHMENTS && (
                    <Popover
                      trigger={["click"]}
                      open={openAttachment}
                      onOpenChange={(open) => {
                        setOpenAttachment(open);
                      }}
                      arrow={false}
                      content={atteachmentsContent}
                    >
                      <button className="size-[1.5vw] hover:bg-slate-100 flex items-center justify-center rounded-[0.4vw]">
                        <img
                          src={ICONS?.Attachments}
                          alt=""
                          className="size-[1vw]"
                        />
                      </button>
                    </Popover>
                  )}
                  <Popover
                    trigger={["click"]}
                    open={showEmojiPicker}
                    onOpenChange={(open) => setShowEmojiPicker(open)}
                    arrow={false}
                    content={
                      <Picker
                        data={data}
                        onEmojiSelect={handleEmojiClick}
                        searchPosition="none"
                        previewPosition="none"
                        width={`5vw`}
                        data-testid="emoji-clicks"
                      />
                    }
                  >
                    <button
                      className="size-[1.5vw] flex items-center justify-center rounded-[0.4vw] p-[0.5vw] hover:bg-slate-100"
                      type="button"
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      data-testid="emoji-click"
                    >
                      <img
                        src={ICONS?.step3EmojiIcon}
                        alt="emoji"
                        className="size-[1vw]"
                      />
                    </button>
                  </Popover>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  className={`p-[0.3vw] rounded-[0.37vw] ${
                    !isMsgSending && allowToSendMessage()
                      ? "cursor-pointer bg-[var(--primary)]"
                      : "cursor-not-allowed bg-[var(--primaryLight)]"
                  }`}
                  onClick={() => {
                    if (!isMsgSending && allowToSendMessage()) {
                      sendMessage(
                        selectedPhoneNumber?.send_from_phone_number_id,
                        selectedPerson?.to_number,
                        currentBrand?.brand_id,
                        updatedData
                      );
                    }
                  }}
                  data-testid="inbox-send-message"
                >
                  {isMsgSending ? (
                    <Loader Width={20} Height={20} loaderBg="white" />
                  ) : (
                    <Send width={"1vw"} height={"1vw"} />
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        open={openTemplateList}
        trigger={["click"]}
        afterOpenChange={(open) => {
          setOpenTemplateList(open);
        }}
        centered
        footer={null}
        closable={false}
        mask={true}
        wrapClassName={"bg-[#00000095]"}
        onCancel={handleCancel}
        destroyOnClose
        width={"60vw"}
        style={{
          width: "100%",
          borderRadius: 20,
          boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
        }}
      >
        <Header
          title="Choose template"
          titleSize="largest"
          rootStyle="!px-[1.5vw] !py-[1vw]"
          handleCancel={handleCancel}
          dataTestId="templateCancel"
        />

        <TemplateListContainer
          className="h-[58vh]"
          isSending={isSending}
          setIsSending={setIsSending}
          changeMedia={FEATURE_FLAG_TEMPLATE_CHANGE_MEDIA}
          changeVariable={FEATURE_FLAG_TEMPLATE_CHANGE_VARIABLE}
          sendTemplate={(data) => {
            const {
              bodyVariables,
              headerVariable,
              message_template_id,
              subcategory_type,
              ...rest
            } = data;

            let variables = [...new Set([...bodyVariables, ...headerVariable])];

            variables = Object.fromEntries(
              variables.map((item) => [
                item?.title?.replace(/{{|}}/g, ""),
                item?.value,
              ])
            );

            sendTemplate({
              sendFrom: selectedPhoneNumber?.send_from_phone_number_id,
              sendTo: selectedPerson?.to_number,
              metaTemplateId: message_template_id,
              brandId: currentBrand?.brand_id,
              subcategory_type: subcategory_type,
              mediaUrl: rest,
              variables: variables,
            });
          }}
        />
      </Modal>
    </div>
  );
}
