import { ICONS } from 'assets/icons';

export const TRIGGERS = [
  {
    id: 1,
    title: 'Triggers',
    list: [
      {
        id: 1,
        icon: ICONS?.AtIcon,
        label: 'Broadcast',
        text: 'Engage your audience with sequences',
        key: 'broadcast',
        disabled: false,
        warning: false,
        error: false,
        color: 'var(--primary)',
      },
    ],
  },
  {
    id: 2,
    title: 'Suggested Triggers',
    list: [
      {
        id: 1,
        icon: '',
        label: 'QR Code scan',
        text: 'Bring users from the QR code scan',
        key: '',
        disabled: false,
        warning: false,
        error: false,
      },
      {
        id: 2,
        icon: '',
        label: 'Message via Post',
        text: 'Response when a user message from Post',
        key: '',
        disabled: true,
        warning: false,
        error: false,
      },
      {
        id: 3,
        icon: '',
        label: 'Message via DM',
        text: 'Lorem ipsum dolor sit amet consectetu',
        key: '',
        disabled: true,
        warning: false,
        error: false,
      },
      {
        id: 4,
        icon: '',
        label: 'Website',
        text: 'Bring users from the website.',
        key: '',
        disabled: false,
        warning: false,
        error: false,
      },
      {
        id: 5,
        icon: '',
        label: 'Message via Page',
        text: 'Response when a user message from Page',
        key: '',
        disabled: true,
        warning: false,
        error: false,
      },
    ],
  },
  {
    id: 3,
    title: 'Ads Trigger',
    list: [
      {
        id: 1,
        icon: '',
        label: 'Meta Ads (CTWA)',
        text: 'Bring users from Facebook ads',
        key: '',
        disabled: false,
        warning: false,
        error: false,
      },
    ],
  },
];

export const INITIAL_TRIGGER_DATA = {
  broadcast: {
    isExisting: true,
    isOpen: true,
    isExpand: true,
    selectedBroadcast: null,
  },
};

export const INITIAL_NEW_BROADCAST_DATA = {
  isExisting: false,
  file: null,
  fileUrl: null,
  sendFrom: null,
  template: null,
  settings: null,
};

export const INITIAL_EXISTING_BROADCAST_DATA = {
  selectedBroadcast: null,
  isExisting: true,
};
