import React from "react";
import { addLineBreake, replaceFormating } from "../../helper";

export default function DocumentMessagePreview({ url, caption, fileName }) {
  return (
    // <div className="flex flex-col gap-2.5">
    <div className="flex flex-col relative w-auto max-w-[26vw] min-h-[3.5vh] bg-[#F4F6F8] rounded-[0.6vw] rounded-tr-none p-[0.5vw]">
      <div className="w-[18vw] h-full">
        <iframe
          title="cover"
          className="imagePreview w-full h-full object-cover rounded-[0.4vw]"
          src={url}
          type="application/pdf"
          // width="100%"
          // height="150px"
        ></iframe>
      </div>
      <p className="text-center font-md weight-medium text-[var(--contentText)] flex items-center justify-between w-full">
        {fileName}
      </p>
      <p
        className="font-md weight-small px-[0.5vw] pt-[0.5vw] break-words"
        dangerouslySetInnerHTML={{
          __html: addLineBreake(replaceFormating(caption)),
        }}
      ></p>
    </div>
  );
}
