import AspApi from "../ApiConfig";

export const createCampaignApi = async (brandId, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/campaigns?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const campaignList = async (brandId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);
  if (query?.sort_by) qpm.push(`sort_by=${query?.sort_by}`);
  if (query?.order_by) qpm.push(`order_by=${query?.order_by}`);
  if (query?.campaign_type) qpm.push(`campaign_type=${query?.campaign_type}`);
  if (query?.goal_type) qpm.push(`goal_type=${query?.goal_type}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "&" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/campaigns?brand_id=${brandId}${qpmString}`
  );
  return res;
};

export const broadcastList = async (brandId, campaignId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/broadcasts?brand_id=${brandId}&campaign_id=${campaignId}&${qpmString}`
  );
  return res;
};

export const contactList = async (
  brandId,
  broadcastId,
  broadcastPartId,
  query
) => {
  let qpm = [];
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/broadcast/contacts?brand_id=${brandId}&broadcast_id=${broadcastId}&broadcast_part=${broadcastPartId}&${qpmString}`
  );
  return res;
};
