import { createSlice } from '@reduxjs/toolkit';

import {
  INITIAL_EXISTING_BROADCAST_DATA,
  INITIAL_NEW_BROADCAST_DATA,
} from 'modules/campaigns/constants/sequence';

const initialState = {
  nodes: [],
  trigger: {
    isOpen: true,
    selected: null,
  },
};

export const sequenceSlice = createSlice({
  name: 'Sequence',
  initialState,
  reducers: {
    update: (state, action) => {
      const {
        primaryKey = null,
        secondaryKey = null,
        data = null,
        key,
        value,
      } = action.payload;
      if (!data) {
        if (primaryKey && !secondaryKey && key) {
          state[primaryKey] = {
            ...state[primaryKey],
            [key]: value,
          };
        } else if (primaryKey && secondaryKey && key) {
          state[primaryKey] = {
            ...state[primaryKey],
            [secondaryKey]: {
              ...state[primaryKey][secondaryKey],
              [key]: value,
            },
          };
        } else {
          state[key] = value;
        }
      } else if (data) {
        if (primaryKey && secondaryKey && key) {
          state[primaryKey] = {
            ...state[primaryKey],
            [secondaryKey]: {
              ...state[primaryKey][secondaryKey],
              [key]: { ...state[primaryKey][secondaryKey][key], ...data },
            },
          };
        } else if (primaryKey && !secondaryKey && key) {
          state[primaryKey] = {
            ...state[primaryKey],
            [key]: { ...state[primaryKey][key], ...data },
          };
        } else if (!primaryKey && !secondaryKey && key) {
          state[key] = {
            ...state[key],
            [key]: { ...state[key], ...data },
          };
        }
      }
    },

    updateBroadCastType: (state, action) => {
      const { type, key, value } = action.payload;
      if (type === 'existing') {
        state.trigger = {
          isOpen: state?.trigger?.isOpen,
          isExisting: true,
          selected: {
            ...state.trigger.selected,
            data: {
              isExisting: true,
              isOpen: state?.trigger?.selected?.isOpen,
              isExpand: state?.trigger?.selected?.isExpand,
              ...INITIAL_EXISTING_BROADCAST_DATA,
            },
          },
        };
      }

      if (type === 'new') {
        state.trigger = {
          isOpen: state?.trigger?.isOpen,
          isExisting: false,
          selected: {
            ...state.trigger.selected,
            data: {
              isExisting: false,
              isOpen: state?.trigger?.selected?.isOpen,
              isExpand: state?.trigger?.selected?.isExpand,
              ...INITIAL_NEW_BROADCAST_DATA,
            },
          },
        };
      }
    },

    updateBroadcastData: (state, action) => {
      const { type, key, value } = action.payload;
      state[primaryKey] = {
        ...state[primaryKey],
        [key]: value,
      };
    },

    reset: (state, action) => {
      state.nodes = [];
      state.trigger = {
        selected: null,
        isExisting: true,
      };
    },
  },
});

export const { update, updateBroadCastType, reset } = sequenceSlice.actions;

export default sequenceSlice.reducer;
