import React from 'react';

//assets
import WhatsAppWithBg from '../../../../assets/customSVG/WhatsAppWithBg';

// helper
import { abbrNum } from '../../../../helper/abbrNum';

export default function PhoneNumbersCard({
  data,
  selectedPhoneNumber,
  handleSelect,
}) {
  const {
    id,
    status,
    display_order,
    uuid,
    slud,
    send_from_phone_name,
    send_from_phone_number_id,
    quality_rating,
    daily_messaging_limit,
    send_from_phone_number,
    country_code,
  } = data;

  return (
    <div
      className={`w-full flex px-[0.5vw] py-[0.35vw] my-[0.5vw] flex-row items-center gap-[0.5vw] rounded-[0.55vw] cursor-pointer transition-colors ease-linear duration-200 ${
        selectedPhoneNumber?.id === id ? 'bg-[#0ACD95] text-white' : ''
      }`}
      onClick={() => handleSelect(data)}
      data-testid = "send-phone-number"
    >
      <WhatsAppWithBg width={'1.4vw'} height={'1.4vw'} />
      <div className={`w-[10vw] flex-column`}>
        <span className='capitalize font-md weight-semibold'>
          {/* {first_name && last_name
            ? `${first_name ?? ''} ${first_name ?? ''}`
            : from} */}
          {send_from_phone_name}
        </span>
        <span className='text-[0.55vw] mt-[0.1vw]'>
          {country_code} {send_from_phone_number}
        </span>
      </div>
      {/* <div
        className={`font-xs weight-bold w-[1.8vw] h-[0.8vw] rounded-full flex items-center justify-center text-center ${
          selectedPhoneNumber?.id === id
            ? 'bg-white text-[#0ACD95]'
            : 'text-[#0ACD95] bg-transparent'
        }`}
      >
        {abbrNum(50, 2)}
      </div> */}
    </div>
  );
}
