import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// api
import { campaignList } from "../../../api/apiClients/campaign";

// assets
import flag from "../../../assets/images/campaigns/flag.svg";

// constants
import {
  CAMPAIGN_TYPE,
  goalTypes,
  SORT,
  STATUS,
} from "../constants/campaignList";

// hooks
import useToggle from "hooks/useToggle";
import { useSearch } from "hooks/useSearch";

// redux
import { useAspSelector } from "../../../test/jest-redux-hooks";

// components
import CampaignsListContainer from "../components/campaigns/CampaignsListContainer";
import CreateCampaignModal from "../../../components/commonComponents/campaigns/CreateCampaignModal";
import Loader from "../../../components/commonComponents/Loader/Index";
import { ICONS } from "../../../assets/icons";
import NoData from "../../../components/commonComponents/Empty/NoData";
import { Skeleton } from "antd";

let page = 1;
let limit = 10;
const goalTypeConvertion = {
  engagement: 1,
  leads: 2,
  awareness: 3,
};

const campaignTypeConvertion = {
  "time-specified": 2,
  perpetual: 3,
};

export default function CampaignsList() {
  const { goalType } = useParams();
  const { currentBrand } = useAspSelector((state) => state.app);

  const [list, setList] = useState(null);
  const [isLoading, setIsLoading] = useToggle(false);
  const [isFilters, setIsFilters] = useToggle(false);

  const [sort, setSort] = useState(null);
  const [sortLabel, setSortLabel] = useState("");

  const [type, setType] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState(null);
  const [status, setStatus] = useState(null);
  const { searchKey, setSearchKey, onChange: onSearchChange } = useSearch("");
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useToggle(false);
  const [isCreateCampaign, setIsCreateCampaign] = useToggle(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [statusTrue, setStatusTrue] = useState(false);

  useEffect(() => {
    setType(CAMPAIGN_TYPE);
    setStatus(STATUS);
    setSort(SORT);
  }, []);

  let data = {};
  sort?.map((s) => {
    if (s?.id === 1) {
      s?.options?.map((o) => {
        if (o?.isSelected) data["sort_by"] = o?.status;
      });
    }

    if (s?.id === 2) {
      s?.options?.map((o) => {
        if (o?.isSelected) data["order_by"] = o?.status;
      });
    }
  });

  useEffect(() => {
    if (data) {
      const payload = {
        page: page,
        limit: limit,
        name: searchKey,
        status: getSelectedStatus(),
        // sort_by: onSortChange(),
        campaign_type: selectedTypes || campaignTypeConvertion[goalType],
        goal_type: goalTypeConvertion[goalType],
        ...data,
      };

      getCampaignList(true, currentBrand?.brand_id, payload);
    }
  }, [
    currentBrand,
    goalType,
    status,
    sort,
    type,
    isCreateCampaign,
    searchKey,
    isModalOpen,
  ]);

  // API
  const getCampaignList = async (loading, brand_id, payload) => {
    // if (loading) setIsLoading(true);
    // else setIsFilters(true);
    if (loading) setIsLoadingSkeleton(true);
    try {
      const res = await campaignList(brand_id, payload);
      setIsLoadingSkeleton(false);
      setList(res?.data);
      setCount(res?.data?.campaigns_count);
    } catch (error) {
      console.warn(error);
    } finally {
      // if (loading) setIsLoading(false);
      // else setIsFilters(false);
      setIsLoadingSkeleton(false);
    }
  };

  const loadNextPage = async () => {
    setIsLoadingSkeleton(false);
    limit += 10;

    const payload = {
      page: page,
      limit: limit,
      name: searchKey,
      status: getSelectedStatus(),
      campaign_type: selectedTypes || campaignTypeConvertion[goalType],
      goal_type: goalTypeConvertion[goalType],
      ...data,
    };
    await getCampaignList(isLoading, currentBrand?.brand_id, payload);
  };

  const onSortChange = (id, data) => {
    const res = sort?.map((s) => {
      if (s.id === id) {
        const updatedOptions = s?.options?.map((o) => {
          if (o?.id === data?.id) return { ...o, isSelected: true };
          return { ...o, isSelected: false };
        });
        return { ...s, options: updatedOptions };
      } else {
        const updatedOptions = s?.options?.map((o) => {
          return { ...o, isSelected: false };
        });
        return { ...s, options: updatedOptions };
      }
    });

    const selectedOptions = res
      .flatMap((s) => s?.options)
      .filter((option) => option?.isSelected);
    setSortLabel(selectedOptions?.[0]?.label);

    setSort(res);
  };

  const clearSort = () => {
    const res = sort?.map((s) => {
      return { ...s, isSelected: false };
    });
    setSort(res);
  };

  // goal type filter
  const onTypeChange = (data) => {
    const res = type?.map((t) => {
      if (t?.id === data?.id) return { ...t, isSelected: !t?.isSelected };
      return t;
    });
    const filters = res?.filter((r) => r?.isSelected === true && r.id);
    const getFilteresId = filters?.map((f) => f?.id);
    setSelectedTypes(getFilteresId);
    setType(res);
  };

  const clearType = () => {
    const res = type?.map((t) => {
      return { ...t, isSelected: false };
    });
    setType(res);
    setSelectedTypes(null);
  };

  // status filter
  const onStatusChange = (data) => {
    const res = status?.map((s) => {
      if (s?.id === data?.id) return { ...s, isSelected: !s?.isSelected };
      return s;
    });
    setStatus(res);
    setStatusTrue(true);
  };

  const clearStatus = () => {
    const res = status?.map((t) => {
      return { ...t, isSelected: false };
    });

    setStatus(res);
    setStatusTrue(false);
  };

  const getSelectedStatus = useCallback(() => {
    const res = status?.filter((s) => s?.isSelected === true);
    const ids = res?.map((r) => r?.status);
    return ids?.length === STATUS?.length ? null : ids;
  }, [status]);

  // modals
  const onCreateCampaignModal = (data) => setIsCreateCampaign(data);

  return (
    <div className="w-full p-9 flex flex-col gap-10 pb-0 relative">
      {/* <Skeleton loading={isLoadingSkeleton} active paragraph={{ rows: 6 }}> */}
      {/* {!searchKey ? (
          <>
            {list?.campaigns_count > 0 ? (
              <>
                <div className="w-full flex items-center justify-between gap-5 my-2">
                  <div className="flex items-center gap-10">
                    <div>
                      <img src={flag} alt="flag" />
                    </div>
                    <div className="w-3/4 flex flex-col justify-between gap-2.5">
                      <h2 className="main-header">Campaigns</h2>
                      <p className="text-sm weight-medium text-[var(--font-600)]">
                        Lorem ipsum dolor sit amet consectetur. Amet viverra
                        sagittis maecenas et sed. Orci porta urna lectus
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      className="flex-row align-center px-4 py-1.5 text-base weight-medium rounded-md bg-[var(--new-primary-bg)] border-2 border-[var(--new-primary)] text-[var(--new-primary)]"
                      onClick={() => {
                        onCreateCampaignModal(true);
                      }}
                    >
                      <img
                        src={ICONS?.CreateCampaign}
                        alt="Create"
                        className="pr-5"
                      />
                      Create Campaign
                    </button>
                  </div>
                </div>

                <CampaignsListContainer
                  isLoading={isFilters}
                  list={list?.campaigns}
                  count={list?.campaigns_count}
                  searchKey={searchKey}
                  setSearchKey={setSearchKey}
                  onSearchChange={onSearchChange}
                  sort={sort}
                  onSortChange={onSortChange}
                  clearSort={clearSort}
                  type={type}
                  onTypeChange={onTypeChange}
                  clearType={clearType}
                  status={status}
                  onStatusChange={onStatusChange}
                  clearStatus={clearStatus}
                  loadNextPage={loadNextPage}
                  sortLabel={sortLabel}
                />
              </>
            ) : (
              <div className="w-full flex justify-center campaign-no-data">
                <NoData
                  title="No Campaigns yet?"
                  content="Go ahead, create your first campaign to start engaging with your visitors and turn them into customers!"
                  modal={
                    <div>
                      {location?.pathname !== "/user/campaign/perpetual" && (
                        <button
                          className="flex-row align-center px-4 py-1.5 text-base weight-medium rounded-md bg-[var(--new-primary-bg)] border-2 border-[var(--new-primary)] text-[var(--new-primary)]"
                          onClick={() => {
                            onCreateCampaignModal(true);
                          }}
                        >
                          <img
                            src={ICONS?.CreateCampaign}
                            alt="Create"
                            className="pr-5"
                          />
                          Create Campaign
                        </button>
                      )}
                    </div>
                  }
                  icon={ICONS?.campaignNoData}
                />
              </div>
            )}
          </>
        ) : (
          <> */}
      <div className="w-full flex items-center justify-between gap-5 my-2">
        <div className="flex items-center gap-10">
          <div>
            <img src={flag} alt="flag" />
          </div>
          <div className="w-3/4 flex flex-col justify-between gap-2.5">
            <h2 className="text-3xl weight-semibold">Campaigns</h2>
            <p className="header-titles">
              Define your business goals and create campaigns to achieve them.
              Reach your audience, boost engagement, and track results with
              ease.
            </p>
          </div>
        </div>
        {location?.pathname !== "/user/campaign/perpetual" && (
          <div className="w-20">
            <button
              className="flex-row align-center px-2 py-1.5 text-base weight-medium rounded-md bg-[var(--new-primary-bg)] border-2 border-[var(--new-primary)] text-[var(--new-primary)]"
              onClick={() => {
                onCreateCampaignModal(true);
              }}
            >
              <img src={ICONS?.CreateCampaign} alt="Create" className="pr-5" />
              Create Campaign
            </button>
          </div>
        )}
      </div>

      <CampaignsListContainer
        isLoading={isFilters}
        list={list?.campaigns}
        count={count}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        onSearchChange={onSearchChange}
        sort={sort}
        onSortChange={onSortChange}
        clearSort={clearSort}
        type={type}
        onTypeChange={onTypeChange}
        clearType={clearType}
        selectedTypes={selectedTypes}
        status={status}
        onStatusChange={onStatusChange}
        clearStatus={clearStatus}
        loadNextPage={loadNextPage}
        sortLabel={sortLabel}
        isLoadingSkeleton={isLoadingSkeleton}
        onCreate={() => onCreateCampaignModal(true)}
        isCreateCampaign={isCreateCampaign}
        setIsCreateCampaign={setIsCreateCampaign}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        statusTrue={statusTrue}
      />
      {/* </> */}
      {/* )} */}
      {/* </Skeleton> */}

      <CreateCampaignModal
        open={isCreateCampaign}
        setOpen={setIsCreateCampaign}
        type={"create"}
      />
    </div>
  );
}

{
  /* {isLoading ? (
        <div className="w-full h-44 flex items-center justify-center bg-white rounded-lg">
          <Loader Width={30} Height={30} />
        </div>
      ) : ( */
}
