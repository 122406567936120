import { Modal, Popover } from "antd";
import React, { useEffect, useState } from "react";

import {
  createCampaignApi,
  updateCampaignApi,
} from "../../../modules/campaigns/api/index";

// helpers
import { cn } from "helper/cn";
import { CAMPAIGN_COLORS, getRandomColors } from "../../../helper/colors";
import {
  formatLocalDate,
  formatLocalEndDate,
} from "../../../helper/convertingDate";

// constants
import {
  CHANNEL_LIST,
  GOAL_EVALUATION_TYPE,
  GOAL_TARGET_TYPES,
  GOALS_LIST,
  GOALS_TARGET,
} from "../../../constant/app/campaigns/CreateCampaigns";
import Loader from "../../commonComponents/Loader/Index";

// components
import Header from "../modals/Header";
import useToggle from "../../../hooks/useToggle";
import ColorsAndEmojiIndegators from "./ColorsAndEmojiIndegators";
import Select from "../select";
import MonthCalender from "../calendar/MonthCalender";
import moment from "moment";
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import { ICONS } from "../../../assets/icons";
import { Button } from "../../form/Button/Button";
import { checkCampaignExits } from "../../../modules/campaign/api/Api";
import { updateToggleToast } from "../../../reduxToolkit/appSlice";
import { images } from "../../../assets/images";
import Calendar from "../calendar/Calendar";
import dayjs from "dayjs";

const initial_data = {
  name: "",
  indicator: getRandomColors(CAMPAIGN_COLORS),
  indicator_type: "Colors",
  channel: "",
  goal: "",
  goal_target: "",
  goal_target_type: GOAL_TARGET_TYPES[0],
  // duration: {
  //   start: moment().toDate(),
  //   end: moment().toDate(),
  // },
  goal_evaluation_type: GOAL_EVALUATION_TYPE[0]?.label,
  duration: {
    start: null,
    end: null,
  },
  starting_date_and_time: null,
  ending_date_and_time: null,
};

const popup = {
  padding: "10px 10px",
};

const campaign_goal_type_id = {
  1: "Engagement",
  2: "Leads",
  3: "Awareness",
};

const campaign_goal_evaluation_type = {
  0: "Overall",
  1: "Unique",
};

export default function CreateCampaignModal({
  type = "create",
  open,
  setOpen,
  data = null,
}) {
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();
  const [isIndicatorOpen, setIsIndicatorOpen] = useToggle(false);
  const [createLoader, setCreateLoader] = useToggle(false);
  const [isGoalTargetOpen, setIsGoalTargetOpen] = useToggle(false);
  const [campaignErrors, setCampaignErrors] = useState({
    name: "",
  });

  const [campaignData, setCampaignData] = useState(null);
  const [characterCount, setCharacterCount] = useState(0);

  useEffect(() => {
    if (open) {
      if (type === "create") {
        setCampaignData(initial_data);
      } else if (type === "edit") {
        if (data) {
          const mappedData = {
            name: data?.name || "",
            indicator: data?.display_color
              ? data?.display_color || getRandomColors(CAMPAIGN_COLORS)
              : data?.display_emoji,
            indicator_type: data?.display_color ? "Colors" : "Emojis",
            channel:
              CHANNEL_LIST.find((ch) => ch.value === data.channel) ||
              CHANNEL_LIST[0],
            goal:
              GOALS_LIST.find((g) => g.value === data.campaign_goal_type_id) ||
              data?.campaign_goal_type,
            goal_target: data.goal_target || "",
            goal_target_type: GOAL_TARGET_TYPES[0],
            goal_evaluation_type:
              GOAL_EVALUATION_TYPE.find(
                (g) => g.value === data.goal_evaluation_type
              ) || data?.goal_evaluation_type,
            // goal_evaluation_type:
            //   data?.goal_evaluation_type === 0 ? "Overall" : "Unique",
            starting_date_and_time: data.starting_date_and_time
              ? moment.utc(data?.starting_date_and_time).format("MMM DD YYYY")
              : null,
            ending_date_and_time: data?.ending_date_and_time
              ? moment.utc(data?.ending_date_and_time).format("MMM DD YYYY")
              : null,
          };

          setCampaignData(mappedData);
        } else setCampaignData(initial_data);
      }
    }
  }, [open, data]);

  const handleCancel = () => setOpen(false);
  const handleCancelIndecator = () => setIsIndicatorOpen(false);
  const handleCancelGoalTarget = () => setIsGoalTargetOpen(false);

  const onChange = (key, value) => {
    setCampaignData((prev) => {
      return { ...prev, [key]: value };
    });
    setCharacterCount(value?.length);
  };

  useEffect(() => {
    if (campaignData?.name) {
      const timeOut = setTimeout(() => {
        if (type === "create" && campaignData?.name?.trim() !== "") {
          checkCampaignExits(
            currentBrand?.brand_id,
            campaignData?.name?.toLowerCase()
          ).then((res) => {
            if (res?.status === 200) {
              setCampaignErrors({
                name: res?.data?.exists ? "Campaign name already exists" : "",
              });
            }
          });
        }

        if (type === "edit" && campaignData?.name !== data?.name) {
          checkCampaignExits(
            currentBrand?.brand_id,
            campaignData?.name?.toLowerCase()
          ).then((res) => {
            if (res?.status === 200) {
              setCampaignErrors({
                name: res?.data?.exists ? "Campaign name already exists" : "",
              });
            }
          });
        }
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [campaignData?.name, type, currentBrand, data?.name]);

  const starting_date_and_time = formatLocalDate(
    campaignData?.starting_date_and_time
  );
  const ending_date_and_time = formatLocalEndDate(
    campaignData?.ending_date_and_time
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setCreateLoader(true);

    const payload = {
      campaign_name: campaignData?.name,
      campaign_type_id: 2,
      campaign_goal_type_id: campaignData?.goal?.value,
      campaign_goal_metric_type_id: campaignData?.goal?.value === 1 ? 2 : 1,
      campaign_color:
        (campaignData?.indicator_type === "Colors" &&
          campaignData?.indicator) ||
        "",
      campaign_emoji:
        (campaignData?.indicator_type === "Emojis" &&
          campaignData?.indicator) ||
        "",
      goal_target: campaignData?.goal_target,
      goal_evaluation_type:
        campaignData?.goal_evaluation_type === "Overall" ? 0 : 1,
      start_date: starting_date_and_time,
      end_date: ending_date_and_time,
      channel: "WhatsApp",
    };

    const apiCall =
      type === "create"
        ? createCampaignApi(currentBrand?.brand_id, payload)
        : updateCampaignApi(data?.id, currentBrand?.brand_id, payload);

    apiCall.then((res) => {
      if (res?.status === 200) {
        setCreateLoader(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: data?.id
                ? "Campaign updated successfully"
                : "Campaign created successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
        // handleCancel();
        // window?.location?.reload();
        setOpen(false);
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: data?.id
                ? "Campaign update failed"
                : "Campaign creation failed",
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    });
  };

  // styles
  const inputContainer = "w-full h-14 flex flex-col gap-2";
  const label = "h-auto flex gap-1.5 text-sm weight-semibold";
  const require = "text-red-400";

  const [startPopoverVisible, setStartPopoverVisible] = useState(false);

  const setFromAndToValues = (from, to) => {
    const parseDate = (dateString) => {
      if (typeof dateString === "object") {
        const parsedDate = moment(dateString, "MMM D, YYYY");
        const newDate = parsedDate.isValid() ? parsedDate.toDate() : null;
        return newDate;
      } else {
        return null;
      }
    };

    const startTimestamp = parseDate(from);
    const endTimestamp = parseDate(to);

    if (startTimestamp && endTimestamp && startTimestamp > endTimestamp) {
      console.error("Start date cannot be after end date.");
      return;
    }

    const currentDate = new Date();

    setCampaignData((prev) => {
      const updatedEndDate = endTimestamp || prev.ending_date_and_time;
      if (
        prev.starting_date_and_time &&
        new Date(prev.starting_date_and_time) < currentDate
      ) {
        return {
          ...prev,
          ending_date_and_time: updatedEndDate,
        };
      } else {
        return {
          ...prev,
          starting_date_and_time: startTimestamp || prev.starting_date_and_time,
          ending_date_and_time: updatedEndDate,
        };
      }
    });

    if (startTimestamp && endTimestamp) {
      setStartPopoverVisible(false);
    }
  };

  const handleDateSelection = (date) => {
    const parsedDate = moment(date, "MMM D, YYYY");
    const selectedDate = parsedDate.isValid() ? parsedDate.toDate() : null;
    if (selectedDate) {
      setCampaignData((prev) => ({
        ...prev,
        ending_date_and_time: selectedDate,
      }));

      setStartPopoverVisible(false);
    }
  };

  const currentDate = new Date();

  const formattedCurrentDate = moment(currentDate).format("YYYY-MM-DD");
  const campaignStartDate =
    type === "edit" && typeof data?.starting_date_and_time === "string"
      ? data?.starting_date_and_time?.split("T")[0]
      : null;

  const [handleRangeReset, setHandleRangeReset] = useState(null);

  const resetRangeCalender = () => {
    setHandleRangeReset(true);
    setTimeout(() => {
      setHandleRangeReset(false);
    }, 0);
  };

  const content =
    campaignStartDate <= formattedCurrentDate ? (
      <Calendar
        heading="Select duration"
        singleSelect={true}
        clickedDateFunc={handleDateSelection}
        style={{ width: "100%", minWidth: "350px", height: "220px" }}
      />
    ) : (
      <Calendar
        heading="Select duration"
        fromTo={setFromAndToValues}
        resetRangeCalender={resetRangeCalender}
        starting_date_and_time={data?.starting_date_and_time}
        ending_date_and_time={data?.ending_date_and_time}
        style={{ width: "100%", minWidth: "350px", height: "220px" }}
      />
    );

  const handleRemove = () => {
    setCampaignData((prev) => ({
      ...prev,
      starting_date_and_time: null,
      ending_date_and_time: null,
    }));
  };

  const endDateOnly = ending_date_and_time?.split(" ")[0];
  const dataDateEndOnly = data?.ending_date_and_time?.split("T")[0];

  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-CA");

  const startDate =
    typeof campaignData?.starting_date_and_time === "string"
      ? campaignData.starting_date_and_time.split(" ")[0] // If string, split to get the date part
      : campaignData?.starting_date_and_time instanceof Date
      ? campaignData.starting_date_and_time.toLocaleDateString("en-CA") // Format as 'YYYY-MM-DD'
      : null;

  const removeIcon =
    data &&
    startDate &&
    (startDate === formattedDate || startDate < formattedDate);

  return (
    <div>
      <Modal
        open={open}
        afterOpenChange={(open) => {
          setOpen(open);
        }}
        width={"37vw"}
        height={"90vh"}
        centered
        footer={null}
        closable={false}
        wrapClassName={"bg-[#00000095]"}
        destroyOnClose={true}
      >
        <Header
          title={type === "create" ? "Create Campaign" : "Update Campaign"}
          subTitle="Build relationships with your audience through sequences."
          titleStyle="text-2xl"
          subTitleStyle="text-sm"
          titleSize="none"
          subTitleSize="none"
          handleCancel={handleCancel}
        />
        <form
          className="px-5 py-4 flex flex-col gap-10"
          onSubmit={handleSubmit}
        >
          <div className={cn(inputContainer)}>
            <label className={cn(label)}>
              Name Your Campaign<span className={cn(require)}>*</span>
            </label>
            <div className="w-full h-11 flex items-center pl-10 gap-2.5 border border-[var(--border-input)] rounded-lg">
              <input
                type="text"
                className="w-100 h-[2.5rem] rounded-lg"
                name="name"
                value={campaignData?.name}
                placeholder="E.g. Spring sale offer"
                onChange={(e) => {
                  const { name, value } = e.target;
                  onChange(name, value);
                }}
                autoComplete="off"
                maxLength={64}
              />

              <span className="text-[0.7rem] font-[500] text-[#616874]">
                {characterCount}/64
              </span>

              <Popover
                trigger={["click"]}
                placement="bottomRight"
                open={isIndicatorOpen}
                onOpenChange={(open) => {
                  setIsIndicatorOpen(open);
                }}
                content={
                  <ColorsAndEmojiIndegators
                    value={campaignData?.indicator}
                    onChange={(value) => {
                      handleCancelIndecator();
                      setCampaignData({
                        ...campaignData,
                        indicator: value?.data,
                        indicator_type: value?.type,
                      });
                    }}
                  />
                }
              >
                <div className="w-2/12 border-l border-[var(--border-100)] flex items-center justify-center gap-2.5">
                  <div
                    className="px-2.5 flex items-center justify-center gap-2.5 cursor-pointer"
                    onClick={() => {
                      setIsIndicatorOpen(!setIsIndicatorOpen);
                    }}
                  >
                    {campaignData?.indicator_type === "Emojis" ? (
                      <div className='size-6 contents-[""] text-lg flex items-center justify-center bg-[var(--BG-50)] rounded-sm'>
                        {campaignData?.indicator}
                      </div>
                    ) : (
                      <div
                        className='size-6 contents-[""] rounded-sm'
                        style={{
                          background: campaignData?.indicator,
                        }}
                      ></div>
                    )}
                    <div
                      className={cn(
                        "transition-transform duration-200 ease-linear",
                        isIndicatorOpen ? "-rotate-180" : "-rotate-0"
                      )}
                    >
                      <div
                        className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                      ></div>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
            <div className="explain-error">{campaignErrors?.name}</div>
          </div>

          <div className={cn(inputContainer)}>
            <label className={cn(label)}>
              Campaign Type<span className={cn(require)}>*</span>
            </label>
            <div className="w-full h-11 flex items-center pl-10 gap-2.5 border border-[var(--border-input)] rounded-lg p-10 text-[0.85rem] font-[500] text-[var(--textBlack)] space-between cursor-not-allowed">
              Time Specified
              <img
                src={ICONS?.modelDropdownIcon}
                alt="modelDropdownIcon"
                className="w-2.5 mr-5"
              />
            </div>
          </div>
          <div className={cn(inputContainer)}>
            <label className={cn(label)}>
              Select Goal<span className={cn(require)}>*</span>
            </label>
            {data?.status !== 11 ? (
              <Select
                value={campaignData?.goal}
                options={GOALS_LIST}
                onChange={(data) => {
                  if (data) onChange("goal", data);
                  onChange("goal_target", "");
                }}
                placeholder="Select Goal"
                rootClassName="w-full h-11 px-3 py-2.5"
                position="bottom"
              />
            ) : (
              <div className="w-full h-11 flex items-center pl-10 gap-2.5 border border-[var(--border-input)] rounded-lg p-10 text-[0.85rem] font-[400] text-[var(--textBlack)] space-between cursor-not-allowed">
                <div className="flex-row align-center gap-2">
                  {campaign_goal_type_id[data?.campaign_goal_type_id]}
                </div>
                <img
                  src={ICONS?.modelDropdownIcon}
                  alt="modelDropdownIcon"
                  className="w-2.5 mr-5"
                />
              </div>
            )}
          </div>

          <div className={cn(inputContainer)}>
            <label className={cn(label)}>
              Goal Target<span className={cn(require)}>*</span>
            </label>
            <div className="w-full h-11 flex justify-between p-10 gap-2.5 border border-[var(--border-input)] rounded-lg">
              <div className="w-full flex items-center border-r-[1px] gap-2">
                <>
                  {campaignData?.goal !== "" &&
                    (campaignData?.goal?.id === 1 ||
                    campaignData?.goal === "Engagement"
                      ? "%"
                      : "#")}
                </>
                <input
                  type="number"
                  className="w-[79%] h-[2.5rem] rounded-lg"
                  placeholder="Enter Target (e.g.100)"
                  name="goal_target"
                  value={campaignData?.goal_target}
                  onChange={(e) => {
                    if (campaignData?.goal) {
                      const { name, value } = e.target;
                      if (campaignData?.goal?.value === 1) {
                        if (value <= 100) {
                          onChange(name, value);
                        }
                      } else {
                        onChange(name, value);
                      }
                    }
                  }}
                  autoComplete="off"
                />
              </div>
              {data?.status !== 11 ? (
                <Select
                  value={campaignData?.goal_evaluation_type}
                  options={GOAL_EVALUATION_TYPE}
                  onChange={(data) => {
                    onChange("goal_evaluation_type", data.label);
                  }}
                  rootClassName="w-20 px-3 py-2.5 !border-r-1 border-none"
                  position="bottom"
                />
              ) : (
                <div className=" w-20 px-3  cursor-not-allowed">
                  {campaign_goal_evaluation_type[data?.goal_evaluation_type]}
                </div>
              )}
            </div>
          </div>

          <div className={cn(inputContainer)}>
            <label className={cn(label)}>
              Choose Channel<span className={cn(require)}>*</span>
            </label>
            <div className="w-full h-11 flex items-center pl-10 gap-2.5 border border-[var(--border-input)] rounded-lg p-10 text-[0.85rem] font-[400] text-[var(--textBlack)] space-between cursor-not-allowed">
              <div className="flex-row align-center gap-2">
                <img src={images.WhatsApp} alt={""} className="size-5" />
                WhatsApp
              </div>
              <img
                src={ICONS?.modelDropdownIcon}
                alt="modelDropdownIcon"
                className="w-2.5 mr-5"
              />
            </div>
          </div>

          <div className={cn(inputContainer)}>
            <label className={cn(label)}>
              Duration<span className={cn(require)}>*</span>
            </label>
            {/* <MonthCalender
              startDate={campaignData?.duration?.start}
              endDate={campaignData?.duration?.end}
              onChange={(data) => onChange("duration", data)}
              rootClassName="w-full justify-start bg-transparent border border-[var(--border-input)] rounded-lg"
              placement="bottom"
              primary={true}
              customRanges={false}
              allowedDate="next"
              // changeYears={true}
              // footer={false}
            /> */}
            <div className="w-full">
              <Popover
                content={content}
                trigger="click"
                arrow={false}
                open={startPopoverVisible}
                overlayClassName="date-popover"
                onOpenChange={(open) => setStartPopoverVisible(open)}
              >
                <div className="flex-column pointer">
                  <div className="create-input flex-row align-center pointer border border-[var(--border-input)] !pl-[10px]">
                    <img src={ICONS.CalendarIcon} alt="CalendarIcon" />
                    <input
                      readOnly={true}
                      style={popup}
                      placeholder="-- Select duration --"
                      value={
                        campaignData?.starting_date_and_time ||
                        campaignData?.ending_date_and_time
                          ? `${
                              campaignData?.starting_date_and_time
                                ? moment(
                                    campaignData?.starting_date_and_time
                                  ).format("ll")
                                : ""
                            } - ${
                              campaignData?.ending_date_and_time
                                ? moment(
                                    campaignData?.ending_date_and_time
                                  ).format("ll")
                                : ""
                            }`
                          : ""
                      }
                      className="create-inputBox pointer"
                    />
                    {campaignData?.start_date &&
                      campaignData?.ending_date_and_time &&
                      !removeIcon && (
                        <img
                          className="popup-exit pointer mr-10"
                          src={ICONS.popupX}
                          alt="popup"
                          onClick={() => handleRemove()}
                          data-id="handleRemove"
                        />
                      )}
                  </div>
                </div>
              </Popover>
            </div>
          </div>

          <div className="w-full flex items-center justify-end px-5">
            {campaignData?.name &&
            campaignData?.goal &&
            campaignData?.goal_evaluation_type &&
            campaignData?.starting_date_and_time &&
            campaignData?.ending_date_and_time &&
            campaignData?.goal_target &&
            !campaignErrors?.name &&
            (campaignData?.name !== data?.name ||
              endDateOnly !== dataDateEndOnly ||
              campaignData?.goal_target !== data?.goal_target) ? (
              <button
                className={cn(
                  "bg-[var(--primary)] text-white px-5 py-1.5 rounded-lg"
                )}
              >
                {createLoader ? (
                  <div
                    className="flex-row align-center justify-center"
                    style={{ width: "70px", height: "18px" }}
                  >
                    <Loader Width={20} Height={20} loaderBg="white" />
                  </div>
                ) : (
                  <>
                    {type === "create" ? "Create Campaign" : "Update Campaign"}
                  </>
                )}
              </button>
            ) : (
              <span className="disable-button">
                {type === "create" ? "Create Campaign" : "Update Campaign"}
              </span>
            )}
          </div>
        </form>
      </Modal>
    </div>
  );
}
