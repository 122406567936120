import AspApi from "api/ApiConfig";

export const OpenedConverstationNumbersList = async (params) => {
  let qpm = [];

  if (params?.brandId) qpm.push(`brand_id=${params?.brandId}`);
  if (params?.businessPhoneNumber)
    qpm.push(`business_phone_number=${params?.businessPhoneNumber}`);
  if (params?.limit) qpm.push(`limit=${params?.limit}`);
  if (params?.page) qpm.push(`page=${params?.page}`);

  let qpmString = "";

  if (qpm?.length > 0) qpmString = "?" + qpm.join("&");
  const res = await AspApi.get(
    `api/v1/svc/inbox/whatsapp_inbox/open_conversation${qpmString}`
  );
  return res;
};

export const ClosedConverstationNumbersList = async (params) => {
  let qpm = [];

  if (params?.brandId) qpm.push(`brand_id=${params?.brandId}`);
  if (params?.businessPhoneNumber)
    qpm.push(`business_phone_number=${params?.businessPhoneNumber}`);
  if (params?.limit) qpm.push(`limit=${params?.limit}`);
  if (params?.page) qpm.push(`page=${params?.page}`);

  let qpmString = "";

  if (qpm?.length > 0) qpmString = "?" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1//svc/inbox/whatsapp_inbox/closed_conversation${qpmString}`
  );
  return res;
};

export const whatsappBusinessNumbers = async (brandId) => {
  const res = await AspApi.get(
    `/api/v1/svc/whatsapp/whatsapp_numbers?brand_id=${brandId}`
  );
  return res;
};

export const conversations = async (params) => {
  let qpm = [];
  if (params?.brandId) qpm.push(`brand_id=${params?.brandId}`);
  if (params?.businessPhoneNumber)
    qpm.push(`business_phone_number=${params?.businessPhoneNumber}`);
  if (params?.customerPhoneNumber)
    qpm.push(`customer_phone_number=${params?.customerPhoneNumber}`);
  if (params?.page) qpm.push(`page=${params?.page}`);
  if (params?.limit) qpm.push(`limit=${params?.limit}`);

  let qpmString = "";

  if (qpm?.length > 0) qpmString = "?" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/svc/whatsapp/conversations/show_conversation${qpmString}`
  );
  return res;
};

export const sendTemplateMessage = async (params) => {
  // query params
  // let qpm = [];
  // if (params?.brandId) qpm.push(`brand_id=${params?.brandId}`);
  // if (params?.metaTemplateId)
  //   qpm.push(`meta_template_id=${params?.metaTemplateId}`);

  // let qpmString = "";
  // if (qpm?.length > 0) qpmString = "?" + qpm.join("&");

  // body params
  let bodyParams = {};
  if (params?.sendFrom) bodyParams["from_number"] = params?.sendFrom;
  if (params?.sendTo) bodyParams["to_number"] = params?.sendTo;
  if (params?.brandId) bodyParams["brand_id"] = params?.brandId;
  if (params?.metaTemplateId)
    bodyParams["template_id"] = params?.metaTemplateId;
  if (params?.subcategory_type)
    bodyParams["subcategory_type"] = params?.subcategory_type;
  if (params?.variables) bodyParams["variables"] = params?.variables;

  const res = await AspApi.post(
    `api/v1/svc/whatsapp/conversations/send_template`,
    bodyParams
  );
  return res;
};

export const sendTextMessage = async (params) => {
  // query params
  let qpm = [];
  // if (params?.brandId) qpm.push(`brand_id=${params?.brandId}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "?" + qpm.join("&");

  // body params
  let bodyParams = {};
  if (params?.brandId) bodyParams["brand_id"] = params?.brandId;
  if (params?.sendFrom) bodyParams["from_number"] = params?.sendFrom;
  if (params?.sendTo) bodyParams["to_number"] = params?.sendTo;
  if (params?.msg) bodyParams["message_body"] = params?.msg;

  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/conversations/send_message${qpmString}`,
    bodyParams
  );
  return res;
};
