import React, { useState } from "react";
// import { HomeFilterList } from "../../../constant/app/Filter/Filter";
import { Popover } from "antd";
import { ICONS } from "../../../assets/icons";

const FilterHome = ({
  fetchApiByFilter,
  filterList,
  dataTestId,
  dataTestIdClick,
}) => {
  const [filter, setFilter] = useState(filterList[0]?.title);
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const onFilterChange = (data) => {
    setFilter(data);
    setPopoverVisible(false);
    fetchApiByFilter(data);
  };

  const SetActionFilter = ({ filterList, onFilterChange }) => {
    return (
      <div className=" align-center justify-center p-10">
        {filterList?.map((item) => (
          <div key={item.id} className="border-b-[1px] last:border-b-0 p-10">
            <span
              className="new-body-text pointer p-0 "
              onClick={() => onFilterChange(item.title)}
              data-testid={dataTestIdClick}
            >
              {item.title}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Popover
      content={
        <SetActionFilter
          filterList={filterList}
          onFilterChange={onFilterChange}
        />
      }
      trigger="click"
      open={isPopoverVisible}
      onOpenChange={setPopoverVisible}
    >
      <div
        className="flex-row items-center border rounded-md py-1 px-3 pointer"
        data-testid={dataTestId}
      >
        <p className="new-body-text">{filter}</p>
        <img src={ICONS?.chevronDownCarousel} className="ml-2" />
      </div>
    </Popover>
  );
};

export default FilterHome;
