import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import CarouselTemplate from "../../components/templates/CarouselTemplate";
import useToggle from "../../../../hooks/useToggle";
import {
  checkToAddOptout,
  filterValidValuesFromButton,
} from "../../../../components/commonComponents/templates/Buttons/utils";
import { INITIAL_STATE, templateReducer } from "../../reducers/templateReducer";
import {
  carouselApprovedGet,
  carouselCardUpdate,
  carouselSaveDraft,
  getTemplate,
  templateUpdate,
  UpdateTemplate,
} from "../../api/Api";
import { LANGUAGE_OPTIONS } from "../../constant";

// helper
import { capitalizeFirstLetter } from "helper/capitalizeFirstLetter";
import { getLanguageCode, getLanguageName } from "helper/setLanguageCode";
import { isValidHttpsUrl } from "helper/checkUrls";
import { removeDoubleStyles, getDataFromTemplate } from "helper/templates";
import { cn } from "../../../../helper/cn";
import { checkMultipleNewLines } from "../../../inbox/helper";
import TemplateDetailsHeader from "../../components/templates/TemplateDetailsHeader";
import { checkOptout } from "../../utils/buttons";
import { getVariableCount } from "../../components/templates";
import TemplatePreview from "../../../../components/commonComponents/templates/TemplatePreview";
import { images } from "../../../../assets/images";

import { isHeaderValid } from "../../utils/header";
import { isBodyValid } from "../../utils/body";
import { isButtonsValid } from "../../utils/buttons";
import { formatCarouelDataToAPI, isCarouselValid } from "../../utils/carousel";
import NewAlert from "../../../../components/commonComponents/modal/alert/NewAlert";
import { Modal } from "antd";
import Alert from "components/commonComponents/modals/Alert";
import { ICONS } from "../../../../assets/icons";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import TemplateBody from "../../components/templates/TemplateBody";
import TemplateHeader from "../../components/templates/TemplateHeader";
import TemplateFooter from "../../components/templates/TemplateFooter";
import TemplateButtons from "../../components/templates/TemplateButtons";
import { updateVariablesFromText } from "../../utils/variables";

import AuthenticationTemplate from "../../components/templates/AuthenticationTemplate";
import { convertMinutesIntoSecond } from "helper/timeConversion";

import Loader from "../../../../components/commonComponents/Loader/Index";
import { isPending } from "@reduxjs/toolkit";

function TemplateDetails() {
  const navigate = useNavigate();
  const { template } = useParams();
  const dispatch = useAspDispatch();
  const templateDetails = useAspSelector((state) => state?.Template);
  const channelsData = useAspSelector((state) => state?.Channel?.data);

  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );

  const {
    id,
    category,
    categoryType,
    language,
    name,
    status,
    custom,
    authentication,
    carousel,
  } = templateData;

  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
  } = custom;

  const template_ids = useCallback(
    () => templateDetails?.id,
    [templateDetails]
  );

  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);

  const [showOnUnloadPopup, setshowOnUnloadPopup] = useToggle(false);
  const [templateName, setTemplateName] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [footerText, setFooterText] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);

  // loading
  const [isPageLoading, setIsPageLoading] = useToggle(true);
  const [loading, setLoading] = useToggle(false);
  const [isDiscard, setIsDiscard] = useToggle(false);
  const [isCategorychanging, setIsCategorychanging] = useToggle(false);
  const [isAddingCarouselCard, setIsAddingCarouselCard] = useToggle(false);

  // errors
  const [templateNameError, setTemplateNameError] = useToggle(false);
  const [isHeaderError, setIsHeaderError] = useToggle(false);

  // editor
  const [showEmojiPicker, setShowEmojiPicker] = useToggle(false);
  const checkOptOutButton = checkToAddOptout(custom?.buttons);
  const languages = getLanguageCode(selectedLanguage?.value);

  useEffect(() => {
    setFooterText(footer?.text);
  }, [footer]);

  useEffect(() => {
    if (isAddingCarouselCard) {
      const timeout = setTimeout(async () => {
        setIsAddingCarouselCard(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [isAddingCarouselCard]);

  useEffect(() => {
    if (isCategorychanging) {
      const timeout = setTimeout(() => {
        setIsCategorychanging(false);
      }, [1000]);

      return () => clearTimeout(timeout);
    }
  }, [isCategorychanging]);

  useEffect(() => {
    if (isPageLoading) {
      const timeout = setTimeout(() => {
        setIsPageLoading(false);
      }, [2000]);

      return () => clearTimeout(timeout);
    }
  }, [isPageLoading]);

  useEffect(() => {
    if (!showOnUnloadPopup) return;
    else {
      const handleonBeforeUnload = (e) => {
        e.preventDefault();
        return (e.returnValue = "");
      };

      window.addEventListener("beforeunload", handleonBeforeUnload, {
        capture: true,
      });

      return () => {
        window.removeEventListener("beforeunload", handleonBeforeUnload, {
          capture: true,
        });
      };
    }
  }, [showOnUnloadPopup]);

  useEffect(() => {
    if (language) {
      const res = LANGUAGE_OPTIONS?.filter(
        (l) => l?.value?.trim()?.toLowerCase() === language?.trim()?.toLowerCase()
      );
      setSelectedLanguage(res?.[0]);
    }
  }, [language]);

  useEffect(() => {
    const categorys = capitalizeFirstLetter(category)
      ? {
          label: capitalizeFirstLetter(category),
          value: category,
        }
      : null;
    setSelectedCategory(categorys);
  }, [category]);

  useEffect(() => {
    if (template === "duplicate-template" && name?.length === 0) {
      setTemplateNameError(true);
      setTemplateName(name);
    } else {
      setTemplateName(name);
    }
  }, [name]);

  // need optimize
  useEffect(() => {
    if (isPageLoading && template_ids() && currentBrand && templateDetails) {
      if (templateDetails?.categoryType === "CAROUSEL") {
        setIsPageLoading(true);
        carouselApprovedGet(template_ids(), currentBrand?.brand_id)
          .then((res) => {
            if (res?.status === 200) {
              templateDispatch({
                type: "update_template",
                data: res?.data?.template,
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching carousel template:", error);
          })
          .finally(() => {
            setIsPageLoading(false);
          });
      } else if (templateDetails?.categoryType !== "CAROUSEL") {
        setIsPageLoading(true);
        getTemplate(template_ids(), { brandId: currentBrand?.brand_id })
          .then((res) => {
            if (res?.status === 200) {
              templateDispatch({
                type: "update_template",
                data: res?.data?.template,
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching carousel template:", error);
          })
          .finally(() => {
            setIsPageLoading(false);
          });
      }
    }
  }, [templateDetails, template_ids, currentBrand]);

  // need to optimize carousel cards auto save
  const cardId = carousel?.cards?.slice(-1)?.[0]?.id;

  // need to optimize carousel cards auto save
  useEffect(() => {
    if (checkCategory() === "CAROUSEL" && carousel?.cards?.length > 0) {
      const cardToUpdate = carousel?.cards?.find(
        (c) => c?.id === selectedCard?.id
      );

      if (cardToUpdate) {
        const headers = {
          type: "HEADER",
          format: carousel?.header,
          example: {
            header_handle: [cardToUpdate?.image],
          },
        };

        const body = {
          type: "BODY",
          text: cardToUpdate?.bodyText,
        };

        if (carousel?.bodyVariables?.length > 0) {
          const variables = updateVariablesFromText(
            body?.text,
            carousel?.bodyVariables
          );
          const filterValues = variables?.map((v) => v?.value);

          if (filterValues?.length > 0) {
            body["example"] = {
              body_text: [filterValues],
            };
          }
        }

        const buttons = cardToUpdate?.buttons?.map((b) => {
          if (b?.type === "URL")
            return { type: b.type, text: b?.text, url: b?.url };
          if (b?.type === "PHONE_NUMBER")
            return {
              type: b?.type,
              text: b?.text,
              phone_number: `${b.code} ${b?.phone_number}`,
            };
          if (b.type === "COPY_CODE")
            return { type: b?.type, text: b?.text, example: [b?.example?.[0]] };

          return { type: b?.type, text: b?.text };
        });

        const updatedCardData = {
          components: [
            headers,
            body,
            {
              type: "BUTTONS",
              buttons: buttons,
            },
          ],
        };

        const isFilled =
          cardToUpdate?.image?.length &&
          cardToUpdate?.bodyText &&
          cardToUpdate?.buttons?.some((b) => b?.text);

        const card_template_id =
          ["create-template", "edit-template", "duplicate-template"].includes(
            template
          ) && template_ids();

        if (isFilled) {
          const debounceTimeout = setTimeout(() => {
            carouselCardUpdate(card_template_id, cardToUpdate?.id, {
              name: cardToUpdate?.name,
              brand_id: currentBrand?.brand_id,
              components: updatedCardData.components,
            }).then(() => {});
          }, 500);

          return () => clearTimeout(debounceTimeout);
        }
      }
    }
  }, [
    carousel,
    category,
    currentBrand,
    templateDetails,
    cardId,
    selectedCard,
    language,
  ]);

  useEffect(() => {
    checkValueHasChanged();
  }, [custom, authentication, carousel]);

  const checkValueHasChanged = () => {
    if (template === "create-template") {
      const data =
        JSON.stringify(INITIAL_STATE) === JSON.stringify(templateData);
      setshowOnUnloadPopup(!data);
    }
    if (
      ["edit-template", "duplicate-template"].includes(template) ||
      !["carousel", "authentication"]?.includes(templateDetails?.categoryType)
    ) {
      const datas = getDataFromTemplate(templateDetails?.details);
      const data = JSON.stringify(datas) === JSON.stringify(templateData);
      setshowOnUnloadPopup(!data);
    }
  };

  const checkCategory = useCallback(
    () => categoryType?.toUpperCase(),
    [categoryType]
  );

  const checkOptoutisExistinCustom = () => checkOptout(custom?.customButtons);

  // check is allow to submit the template
  const checkAllowToSubmit = useCallback(() => {
    // Custom and Utility validations
    const isHeader = isHeaderValid(header, headerVariable);
    const isBody = isBodyValid(body, bodyVariables);
    const isButtons = isButtonsValid(buttons, ctaButtons, customButtons);

    // Authentication template validateion
    const isExpiryTime = authentication?.content?.expiryTimeForCode
      ? authentication?.content?.expiryTime >= 1 &&
        authentication?.content?.expiryTime <= 90
      : true;

    const isAuthenticationButtons = authentication?.buttons?.filter(
      (b) => b.text === ""
    );

    // Carousel validations
    const isCarousel = isCarouselValid(carousel);

    const res = LANGUAGE_OPTIONS?.filter(
      (l) =>
        l.label === selectedLanguage?.label &&
        l.value === selectedLanguage?.value
    );

    return isCategorychanging
      ? false
      : checkCategory() === "AUTHENTICATION"
      ? templateName &&
        !templateNameError &&
        authentication?.body?.length > 0 &&
        isExpiryTime &&
        isAuthenticationButtons?.length === 0
      : checkCategory() === "CAROUSEL"
      ? templateName && !templateNameError && isBody && isCarousel
      : templateName &&
        !templateNameError &&
        isHeader &&
        !isHeaderError &&
        isBody &&
        isButtons &&
        checkOptoutisExistinCustom() &&
        res?.length > 0;
  }, [
    isCategorychanging,
    checkCategory,
    templateDetails,
    templateNameError,
    custom,
    carousel,
    authentication,
    templateName,
    isHeaderError,
  ]);

  // handle buttons
  const updateButtons = useCallback(
    ({ updateKey, data, key, value, id }) => {
      if (data?.format === "OPT_OUT") {
        onDataChange({
          type: "footer",
          text: "Not interested? Tap Stop promotions",
        });
        setFooterText("Not interested? Tap Stop promotions");
      }

      if (updateKey === "remove") {
        const removed = buttons?.filter((b) => b.id === id);
        if (removed?.length > 0) {
          if (removed[0]?.format === "OPT_OUT") {
            onDataChange({
              type: "footer",
              text: "",
            });
            setFooterText("");
          }
        }
      }
      onDataChange({
        type: "buttons",
        updateKey: updateKey,
        data: data
          ? { id: id, ...data }
          : {
              id: id,
              key: key,
              value: value,
            },
      });
    },
    [buttons]
  );

  useEffect(() => {
    if (body) {
      let data = {};
      const languages = getLanguageCode(selectedLanguage?.value);
      const { components } = formatCarouelDataToAPI({ body, bodyVariables });
      data = {
        category: selectedCategory?.value,
        name: templateName,
        language: languages,
        brand_id: currentBrand?.brand_id,
        components: [...components, { type: "CAROUSEL", cards: [] }],
        save_as_draft: true,
      };

      const card_template_id =
        ["create-template", "edit-template", "duplicate-template"].includes(
          template
        ) && template_ids();

      const debounceTimeout = setTimeout(() => {
        if (templateDetails?.categoryType === "CAROUSEL") {
          carouselSaveDraft(card_template_id, {
            ...data,
          });
        }
      }, 500);
      return () => clearTimeout(debounceTimeout);
    }
  }, [
    body,
    bodyVariables,
    selectedCategory,
    templateDetails,
    selectedLanguage,
    currentBrand,
  ]);

  const createAndUpdateTemplateData = async (draft = "false") => {
    setLoading(true);

    let data = {};
    let component = [];

    if (header) {
      component.push(header);
    }

    if (!checkOptOutButton && footer && footer?.text?.length > 0) {
      component.push(footer);
    } else if (checkOptOutButton && footer && footer?.text?.length > 0) {
      component.push(footer);
    }

    if (bodyVariables?.length > 0) {
      component.push({
        type: "BODY",
        text: body,
        example: {
          body_text: [
            bodyVariables?.map((b) => {
              return b.value;
            }),
          ],
        },
      });
    } else {
      component.push({
        type: "BODY",
        text: removeDoubleStyles(body),
      });
    }
    if (buttons?.length > 0) {
      const cta = filterValidValuesFromButton(ctaButtons);
      const custom = filterValidValuesFromButton(customButtons);
      component.push({
        type: "BUTTONS",
        buttons:
          buttonsOrder === "ctaButtons"
            ? [...cta, ...custom]
            : [...custom, ...cta],
      });
    }

    if (checkCategory() === "AUTHENTICATION") {
      const autheticationButtons = authentication?.buttons?.map((b) => {
        const { id, label, placeholder, ...rest } = b;
        return rest;
      });

      data = {
        name: templateName,
        category: selectedCategory?.value,
        language: languages,
        brand_id: currentBrand?.brand_id,
        save_as_draft: draft,
        components: [
          {
            type: "BODY",
            add_security_recommendation:
              authentication?.content?.securityRecommendation,
          },
          {
            type: "BUTTONS",
            buttons: autheticationButtons,
          },
        ],
      };

      if (authentication?.content?.expiryTimeForCode)
        data = {
          ...data,
          components: [
            ...data?.components,
            {
              type: "FOOTER",
              code_expiration_minutes: authentication?.content?.expiryTime,
            },
          ],
        };

      if (authentication?.messageValidity?.validity) {
        data["validity_period"] = convertMinutesIntoSecond(
          authentication?.messageValidity?.period
        );
      } else {
        const { validity_period, ...rest } = data;
        data = rest;
      }
    } else if (checkCategory() === "CAROUSEL") {
      data = {
        category: selectedCategory?.value,
        name: templateName,
        language: languages,
        brand_id: currentBrand?.brand_id,
        components: [...component, { type: "CAROUSEL", cards: [] }],
        save_as_draft: draft,
      };
    } else {
      data = {
        category: selectedCategory?.value,
        name: templateName,
        language: languages,
        brand_id: currentBrand?.brand_id,
        components: component,
        save_as_draft: draft,
      };
    }
    const card_template_id =
      ["create-template", "edit-template", "duplicate-template"].includes(
        template
      ) && template_ids();
    if (checkCategory() === "CAROUSEL") {
      carouselSaveDraft(card_template_id, {
        ...data,
      })
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Template Updated successfully",
                  status: "Success",
                  duration: "",
                },
              ])
            );
            navigate("/user/channels/whatsapp");
          } else {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Template update creation failed. Please try again!",
                  status: "Error",
                  duration: "",
                },
              ])
            );
          }
        })
        .catch((error) => {
          console.error("Error creating/updating template:", error);
        });
    }

    if (
      // template === 'edit-template' &&
      // templateDetails?.details?.status !== 'DRAFT' &&
      checkCategory() !== "CAROUSEL"
    ) {
      if (
        templateDetails?.details?.validity_period &&
        !authentication?.messageValidity?.validity
      ) {
        data["validity_period"] = -1;
      }
      templateUpdate(template_ids(), data)
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Template Updated successfully",
                  status: "Success",
                  duration: "",
                },
              ])
            );
            navigate("/user/channels/whatsapp");
          } else {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Template update creation failed. Please try again!",
                  status: "Error",
                  duration: "",
                },
              ])
            );
          }
        })
        .catch((error) => {
          console.error("Error creating/updating template:", error);
        });
    }
  };

  const onDataChange = ({
    type,
    primaryKey,
    secondaryKey,
    updateKey,
    id,
    key,
    value,
    text,
    data,
  }) => {
    const payload = {
      type: type,
      key: key,
      value: value,
    };

    if (primaryKey) payload["primaryKey"] = primaryKey;
    if (secondaryKey) payload["secondaryKey"] = secondaryKey;
    if (updateKey) payload["updateKey"] = updateKey;
    if (id) payload["id"] = id;
    if (text) payload["text"] = text;
    if (data) payload["data"] = data;

    templateDispatch(payload);
  };

  useEffect(() => {
    let data = {};
    if (checkCategory() !== "CAROUSEL") {
      if (checkCategory() === "AUTHENTICATION") {
        const autheticationButtons = authentication?.buttons?.map((b) => {
          const { id, label, placeholder, ...rest } = b;
          return rest;
        });
        data = {
          name: templateName,
          category: selectedCategory?.value,
          language: languages,
          brand_id: currentBrand?.brand_id,
          save_as_draft: true,
          components: [
            {
              type: "BODY",
              add_security_recommendation:
                authentication?.content?.securityRecommendation,
            },
            {
              type: "BUTTONS",
              buttons: autheticationButtons,
            },
          ],
        };

        if (authentication?.content?.expiryTimeForCode)
          data = {
            ...data,
            components: [
              ...data?.components,
              {
                type: "FOOTER",
                code_expiration_minutes: authentication?.content?.expiryTime,
              },
            ],
          };

        if (authentication?.messageValidity?.validity) {
          data["validity_period"] = convertMinutesIntoSecond(
            authentication?.messageValidity?.period
          );
        } else {
          const { validity_period, ...rest } = data;
          data = rest;
        }

        const debounceTimeout = setTimeout(() => {
          templateUpdate(template_ids(), data);
        }, 500);
        return () => clearTimeout(debounceTimeout);
      } else {
        let component = [];
        if (header) {
          component.push(header);
        }

        if (!checkOptOutButton && footer && footer?.text?.length > 0) {
          component.push(footer);
        } else if (checkOptOutButton && footer && footer?.text?.length > 0) {
          component.push(footer);
        }

        if (bodyVariables?.length > 0) {
          component.push({
            type: "BODY",
            text: body,
            example: {
              body_text: [
                bodyVariables?.map((b) => {
                  return b.value;
                }),
              ],
            },
          });
        } else {
          component.push({
            type: "BODY",
            text: removeDoubleStyles(body),
          });
        }
        if (buttons?.length > 0) {
          const cta = filterValidValuesFromButton(ctaButtons);
          const custom = filterValidValuesFromButton(customButtons);
          component.push({
            type: "BUTTONS",
            buttons:
              buttonsOrder === "ctaButtons"
                ? [...cta, ...custom]
                : [...custom, ...cta],
          });
        }

        let data = {};

        data = {
          category: selectedCategory?.value,
          name: templateName,
          language: languages,
          brand_id: currentBrand?.brand_id,
          components: component,
          save_as_draft: true,
        };

        const debounceTimeout = setTimeout(() => {
          templateUpdate(template_ids(), data);
        }, 500);
        return () => clearTimeout(debounceTimeout);
      }
    }
  }, [
    authentication,
    custom,
    languages,
    footer,
    templateName,
    checkOptOutButton,
  ]);

  if (isCategorychanging || isPageLoading) {
    return (
      <div className="w-full h-auto p-[0.5vw]">
        <div className="bg-white flex items-center justify-center p-6 my-4 rounded">
          <Loader Width={30} Height={30} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`w-full max-h-[92vh] overflow-hidden ${
        loading ? "cursor-not-allowed" : "cursor-default"
      }`}
    >
      {isCategorychanging ? (
        <div className="w-full h-auto p-[0.5vw]">
          <div className="bg-white flex items-center justify-center p-6 my-4 rounded">
            <Loader Width={30} Height={30} />
          </div>
        </div>
      ) : (
        <>
          <TemplateDetailsHeader
            category={checkCategory}
            checkAllowToSubmit={checkAllowToSubmit}
            createAndUpdateTemplateData={createAndUpdateTemplateData}
            isDiscard={isDiscard}
            setIsDiscard={setIsDiscard}
            templateName={templateName}
            setTemplateName={setTemplateName}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            isCategorychanging={isCategorychanging}
            setIsCategorychanging={setIsCategorychanging}
            templateNameError={templateNameError}
            setTemplateNameError={setTemplateNameError}
            buttons={buttons}
            loading={loading}
            onChange={onDataChange}
            status={status}
            updateButtons={updateButtons}
          />
          <div className="w-full flex justify-center gap-[0.5vw] overflow-hidden p-[0.5vw]">
            <div className="w-[25vw] bg-[var(--white)]">
              <div className="w-full font-default weight-semibold text-[var(--contentText)] px-[1vw] py-[1vw]">
                Message Preview
              </div>
              <div className="max-w-[18.5vw] w-full flex-1 overflow-hidden px-2.5">
                <div className="h-[6vh] flex items-center gap-[0.5vw] bg-[#077C70] rounded-t-[1.5vw] px-[0.6vw]">
                  <div className="size-[2.1vw] bg-[var(--white)] rounded-full flex items-center justify-center font-xs">
                    Logo
                  </div>
                  <div className="text-[var(--white)]">
                    <h3 className="font-md weight-bold capitalize leading-[1.2vw]">
                      {channelsData?.whatsapp_business_account_name}
                    </h3>
                  </div>
                </div>
                <TemplatePreview
                  category={checkCategory()}
                  header={header}
                  headerVariable={headerVariable}
                  body={body}
                  bodyVariables={bodyVariables}
                  footer={footer}
                  buttons={buttons}
                  buttonsOrder={buttonsOrder}
                  ctaButtons={ctaButtons}
                  customButtons={customButtons}
                  data={templateDetails?.details}
                  className={
                    "w-full rounded-none h-[55vh] max-[1600px]:h-[55vh]"
                  }
                  authentication={authentication}
                  carousel={carousel}
                  isAddingCarouselCard={isAddingCarouselCard}
                  setIsAddingCarouselCard={setIsAddingCarouselCard}
                />
                <img
                  src={images?.TemplatePreviewBottom}
                  alt="send msg"
                  className="w-[17.2vw]  rounded-b-[1.5vw]"
                />
              </div>
            </div>
            <div
              className={`w-full bg-[var(--white)] px-[0.7vw] py-[1vw] overflow-y-scroll ${
                templateNameError ? "h-[72.5vh]" : "h-[74.5vh]"
              }`}
            >
              {checkCategory() === "AUTHENTICATION" ? (
                <AuthenticationTemplate
                  data={authentication}
                  dispatch={templateDispatch}
                  onChange={onDataChange}
                />
              ) : checkCategory() === "CAROUSEL" ? (
                <CarouselTemplate
                  id={template_ids()}
                  language={selectedLanguage?.value ?? ""}
                  body={body}
                  dispatch={templateDispatch}
                  headerVariable={headerVariable}
                  variables={bodyVariables}
                  allow={!loading}
                  showEmojiPicker={showEmojiPicker}
                  setShowEmojiPicker={setShowEmojiPicker}
                  data={carousel}
                  isAddingCarouselCard={isAddingCarouselCard}
                  setIsAddingCarouselCard={setIsAddingCarouselCard}
                  setSelectedCard={setSelectedCard}
                  selectedCard={selectedCard}
                  onChange={onDataChange}
                />
              ) : (
                <>
                  <div className="border-b-[0.08vw] border-[var(--card-border)] pb-[1.5vw]">
                    <TemplateHeader
                      header={header}
                      headerVariable={headerVariable}
                      dispatch={templateDispatch}
                      allow={!loading}
                      onChange={onDataChange}
                      setIsHeaderError={setIsHeaderError}
                    />
                  </div>
                  <div className="my-[1vw]">
                    <TemplateBody
                      title="Body"
                      subTitle="Enter the text for your message in the language that you've selected."
                      type="default"
                      language={selectedLanguage?.value ?? ""}
                      body={body}
                      dispatch={templateDispatch}
                      headerVariable={headerVariable}
                      variables={bodyVariables}
                      allow={!loading}
                      showEmojiPicker={showEmojiPicker}
                      setShowEmojiPicker={setShowEmojiPicker}
                      onVariableChange={(value) => {
                        onDataChange({
                          type: "update_variable",
                          key: value?.key,
                          value: value.value,
                        });
                      }}
                      onChange={(value) => {
                        onDataChange({
                          type: "body",
                          updateKey: "custom",
                          text: value?.text,
                        });
                      }}
                    />
                  </div>
                  <div className="mt-[1.5vw]">
                    <TemplateFooter
                      footer={footer}
                      dispatch={templateDispatch}
                      disabled={!checkOptOutButton}
                      allow={!loading}
                      text={footerText}
                      setText={setFooterText}
                      onChange={onDataChange}
                    />
                  </div>
                  <div className="w-full mt-[1.5vw] pt-[1.5vw] border-t-[0.08vw] border-[var(--card-border)]">
                    <TemplateButtons
                      buttons={buttons}
                      ctaButtons={ctaButtons}
                      customButtons={customButtons}
                      order={buttonsOrder}
                      updateButtons={updateButtons}
                      allow={!loading}
                      onChange={onDataChange}
                      category={checkCategory()}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TemplateDetails;
