import React, { useCallback, useEffect, useState } from "react";

// images
import noData from "assets/images/inbox/noData.svg";

// consts
import { conversationTypes } from "constant/app/inbox/Inbox";
import { conversationMessageList } from "constant/app/inbox/Inbox";
import { FEATURE_FLAG_STARED_LIST } from "../../constants/FeatureFlag";

// hooks
import useToggle from "hooks/useToggle";
import { useSearch } from "hooks/useSearch";
import { useSelect } from "hooks/useSelect";

// antd
import {
  ClosedConverstationNumbersList,
  OpenedConverstationNumbersList,
  conversations,
} from "../../api/Api";
import { useAspSelector } from "../../../../test/jest-redux-hooks";

// components
import SearchBar from "components/commonComponents/searchBar";
import CustomerPhoneNumber from "./CustomerPhoneNumber";
import ConversationsTypes from "../conversation/ConversationsType";
import ConversationContainer from "./ConversationContainer";
import Profile from "../profile";
import NoData from "components/commonComponents/Nodata/index";

const cleanParams = (value) =>
  value?.replaceAll("-", "")?.replaceAll(" ", "")?.trim();

let count = 0;
let phoneNumberCount = 0;
let limit = 10;
let page = 1;
let converstationLimit = 20;
let converstationPage = 1;

export default function Conversation({
  primaryLoading,
  loading,
  setLoading,
  selectedPhoneNumber,
}) {
  const CONVERSATION_TYPES = FEATURE_FLAG_STARED_LIST
    ? conversationTypes
    : conversationTypes?.slice(0, 2);
  const { currentBrand } = useAspSelector((state) => state?.app);

  const { searchKey, setSearchKey, onChange } = useSearch("");

  const [conversationList, setConversationList] = useState([]);
  const [selectedConversationType, setSelectedConversationType] = useSelect(
    CONVERSATION_TYPES?.[0]
  );
  const [selectedPerson, setSelectedPerson] = useSelect(null);

  // conversations
  const [alert, setAlert] = useToggle(false);
  const [isConversationPrimary, setIsConversationPrimary] = useToggle(false);
  const [isConversationSecondary, setIsConversationSecondary] =
    useToggle(false);
  const [conversation, setConversation] = useState([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (alert) setAlert(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [alert]);

  useEffect(() => {
    if (currentBrand?.brand_id && selectedPhoneNumber) {
      fetchOpenedNumberList(
        true,
        currentBrand?.brand_id,
        cleanParams(
          `${selectedPhoneNumber?.country_code?.slice(1)}${
            selectedPhoneNumber?.send_from_phone_number
          }`
        ),
        converstationLimit,
        converstationPage
      );
    }
  }, [selectedPhoneNumber]);

  useEffect(() => {
    const intervel = setInterval(() => {
      if (currentBrand?.brand_id && selectedPhoneNumber) {
        if (selectedConversationType?.type === "opened")
          fetchOpenedNumberList(
            false,
            currentBrand?.brand_id,
            cleanParams(
              `${selectedPhoneNumber?.country_code?.slice(1)}${
                selectedPhoneNumber?.send_from_phone_number
              }`
            ),
            converstationLimit,
            converstationPage
          );

        if (selectedConversationType?.type === "closed")
          fetchClosedNumberList(
            false,
            currentBrand?.brand_id,
            cleanParams(
              `${selectedPhoneNumber?.country_code?.slice(1)}${
                selectedPhoneNumber?.send_from_phone_number
              }`
            ),
            converstationLimit,
            converstationPage
          );
      }
    }, [5000]);

    return () => clearInterval(intervel);
  }, [selectedPhoneNumber, currentBrand, selectedConversationType]);

  useEffect(() => {
    const intervel = setInterval(() => {
      if (
        selectedPerson &&
        selectedPerson?.from_number &&
        selectedPerson?.to_number
      ) {
        getConversations(
          "debounce",
          false,
          currentBrand?.brand_id,
          limit,
          page,
          selectedPerson?.from_number,
          selectedPerson?.to_number
        );
      }
    }, [4000]);

    return () => clearInterval(intervel);
  }, [selectedPerson, limit, page]);

  // fetch the inbox phone numbers list
  const fetchOpenedNumberList = async (
    load,
    brandId,
    phoneNumber,
    limit,
    page
  ) => {
    if (load) setLoading(true);
    try {
      const res = await OpenedConverstationNumbersList({
        brandId: brandId,
        businessPhoneNumber: phoneNumber,
        limit: limit,
        page: page,
      });
      // page === 1
      //   ? setConversationList(res?.data?.phone_number_list)
      //   : setConversationList([
      //       ...conversationList,
      //       ...res?.data?.phone_number_list,
      //     ]);
      setConversationList(res?.data?.phone_number_list);
      phoneNumberCount = res?.data?.phone_numbers_count;
    } catch (e) {
      console.warn(e);
    } finally {
      if (load) setLoading(false);
    }
  };

  const fetchClosedNumberList = async (
    load,
    brandId,
    phoneNumber,
    limit,
    page
  ) => {
    if (load) setLoading(true);
    try {
      const res = await ClosedConverstationNumbersList({
        brandId: brandId,
        businessPhoneNumber: phoneNumber,
        limit: limit,
        page: page,
      });
      // page === 1
      //   ? setConversationList(res?.data?.phone_number_list)
      //   : setConversationList([
      //       ...conversationList,
      //       ...res?.data?.phone_number_list,
      //     ]);
      setConversationList(res?.data?.phone_number_list);
      phoneNumberCount = res?.data?.phone_numbers_count;
    } catch (e) {
      console.warn(e);
    } finally {
      if (load) setLoading(false);
    }
  };

  const loadNextPage = () => {
    // converstationPage += 1;
    converstationLimit += 20;
    if (currentBrand?.brand_id && selectedPhoneNumber) {
      if (selectedConversationType?.type === "opened")
        fetchOpenedNumberList(
          false,
          currentBrand?.brand_id,
          cleanParams(
            `${selectedPhoneNumber?.country_code?.slice(1)}${
              selectedPhoneNumber?.send_from_phone_number
            }`
          ),
          converstationLimit,
          converstationPage
        );

      if (selectedConversationType?.type === "closed")
        fetchClosedNumberList(
          false,
          currentBrand?.brand_id,
          cleanParams(
            `${selectedPhoneNumber?.country_code?.slice(1)}${
              selectedPhoneNumber?.send_from_phone_number
            }`
          ),
          converstationLimit,
          converstationPage
        );
    }
  };

  // fetch the conversation b/w selected customer number
  const getConversations = async (
    type,
    load,
    brandId,
    limit,
    page,
    businessPhoneNumber,
    customerPhoneNumber
  ) => {
    if (type !== "debounce" && load) setIsConversationPrimary(true);
    if (type !== "debounce" && !load) setIsConversationSecondary(true);
    try {
      const res = await conversations({
        brandId: brandId,
        businessPhoneNumber: cleanParams(businessPhoneNumber),
        customerPhoneNumber: cleanParams(customerPhoneNumber),
        limit: limit,
        page: page,
      });
      const reverse = res?.data?.wapp_conversation?.reverse();
      // if (type === 'scroll') setConversation([...reverse, ...conversation]);
      if (type === "scroll") setConversation(reverse);
      if (type === "select") setConversation(reverse);
      count = res?.data?.wapp_conversation_count;
    } catch (e) {
      console.warn(e);
    } finally {
      if (type !== "debounce" && load) setIsConversationPrimary(false);
      if (!load) setIsConversationSecondary(false);
    }
  };

  const onConversationTypeChange = async (values) => {
    setSelectedConversationType(values);
    setConversationList([]);
    phoneNumberCount = 0;
    converstationPage = 1;
    converstationLimit = 20;
    setSelectedPerson(null);

    if (values?.type === "opened")
      await fetchOpenedNumberList(
        true,
        currentBrand?.brand_id,
        cleanParams(
          `${selectedPhoneNumber?.country_code?.slice(1)}${
            selectedPhoneNumber?.send_from_phone_number
          }`
        ),
        converstationLimit,
        converstationPage
      );

    if (values?.type === "closed")
      await fetchClosedNumberList(
        true,
        currentBrand?.brand_id,
        cleanParams(
          `${selectedPhoneNumber?.country_code?.slice(1)}${
            selectedPhoneNumber?.send_from_phone_number
          }`
        ),
        converstationLimit,
        converstationPage
      );
  };

  const onConversationStarChange = (value) => {};

  const onCloseConversation = (value) => {};

  // handle select the customer
  const onSelectPerson = (item) => {
    setConversation([]);
    // page = 1;
    count = 0;
    limit = 10;
    setSelectedPerson(item);
    getConversations(
      "select",
      true,
      currentBrand?.brand_id,
      limit,
      page,
      item?.from_number,
      item?.to_number
    );
  };

  return (
    <div className="w-full h-full">
      <div className="w-full border-b-[0.1vw] border-[var(--border-50)] p-[0.5vw]">
        {/* <div className="w-[19.5vw] h-[2.1vw]">
          <SearchBar
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            onChange={(value) => onChange(value)}
            search="inbox-search"
            searchCancel="inboxSearchCancel"
          />
        </div> */}
      </div>

      <div className="max-w-[77vw] m-0 h-full flex">
        <div className="max-w-[19vw] min-w-[19vw] border-r-[0.1vw] border-[var(--border-50)] flex flex-col gap-y-[0.1vw]">
          <ConversationsTypes
            loading={primaryLoading ? true : loading}
            conversations={CONVERSATION_TYPES}
            conversationList={conversationList}
            selectedConversationType={selectedConversationType}
            onConversationTypeChange={onConversationTypeChange}
          />
          <CustomerPhoneNumber
            primaryLoading={primaryLoading}
            loading={primaryLoading ? true : loading}
            searchKey={searchKey}
            conversationList={conversationList}
            selectedPerson={selectedPerson}
            selectedConversationType={selectedConversationType}
            onSelectPerson={onSelectPerson}
            onConversationStarChange={onConversationStarChange}
            onCloseConversation={onCloseConversation}
            count={phoneNumberCount}
            loadNextPage={loadNextPage}
          />
        </div>
        {selectedPerson ? (
          <>
            <div className="max-w-[41vw] min-w-[41vw] border-r-[0.1vw] border-[var(--border-50)]">
              <ConversationContainer
                loading={isConversationPrimary}
                setLoading={setIsConversationPrimary}
                secondaryLoading={isConversationSecondary}
                setSecondaryLoading={setIsConversationSecondary}
                selectedPhoneNumber={selectedPhoneNumber}
                selectedPerson={selectedPerson}
                selectedConversationType={selectedConversationType}
                conversation={conversation}
                onConversationStarChange={onConversationStarChange}
                onCloseConversation={onCloseConversation}
                alert={alert}
                getConversations={getConversations}
                loadNext={() => {
                  if (conversation?.length < count) {
                    // page += 1;
                    limit += 20;
                    getConversations(
                      "scroll",
                      false,
                      currentBrand?.brand_id,
                      limit,
                      page,
                      selectedPerson?.from_number,
                      selectedPerson?.to_number
                    );
                  } else if (conversation?.length > count) {
                    setAlert(true);
                  }
                }}
                count={count}
              />
            </div>
            <div className="max-w-[19.8vw] min-w-[19.8vw] overflow-hidden">
              <Profile selectedPerson={selectedPerson} />
            </div>
          </>
        ) : (
          <div className="w-full h-[75vh]">
            <NoData
              icon={noData}
              title="No contacts selected"
              content={
                "Begin your conversation by choose any one contact in your contact list."
              }
              iconStyle={"w-[10vw]"}
              style={"max-w-[22vw]"}
              titleClassName="!font-largest"
            />
          </div>
        )}
      </div>
    </div>
  );
}
