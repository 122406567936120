import { ICONS } from "../../../assets/icons";

export const overviewData = [
  {
    id: 1,
    label: "Sent",
    icon: ICONS?.sentOverview,
    count: 0,
    data: [],
    color: "#B49FF9",
  },
  {
    id: 2,
    label: "Delivered",
    icon: ICONS?.deliveredOverview,
    count: 0,
    data: [],
    color: "#02D1C5",
  },
  {
    id: 3,
    label: "Read",
    icon: ICONS?.readOverview,
    count: 0,
    data: [],
    color: "#1778F2",
  },
  {
    id: 4,
    label: "Bounced",
    icon: ICONS?.bouncedOverview,
    count: 0,
    data: [],
    color: "#FF543E",
  },
];

export const performanceData = [
  {
    id: 1,
    label: "Clicks",
    icon: ICONS?.performanceClicks,
    count: 0,
    value: 7,
  },
  {
    id: 2,
    label: "Reactions",
    icon: ICONS?.performanceReactions,
    count: 0,
    value: 8,
  },
  {
    id: 3,
    label: "Leads",
    icon: ICONS?.performanceLeads,
    count: 0,
    value: 6,
  },
  {
    id: 4,
    label: "Blocked",
    icon: ICONS?.performanceBlcoked,
    count: 0,
    value: -2,
  },
];

export const performanceLeads = [
  {
    id: 1,
    label: "Clicks",
    icon: ICONS?.performanceClicks,
    count: 0,
    value: 7,
  },
  {
    id: 2,
    label: "Leads",
    icon: ICONS?.performanceLeads,
    count: 0,
    value: 6,
  },
  {
    id: 3,
    label: "Conversions",
    icon: ICONS?.leadsGreen,
    count: 0,
    value: null,
  },
];
