import React from "react";
import { addLineBreake, replaceFormating } from "../../helper";

export default function VideoMessagePreview({ url, caption }) {
  return (
    // <div className="flex flex-col gap-2.5">
    <div className="flex flex-col relative w-auto max-w-[26vw] min-h-[3.5vh] bg-[#F4F6F8] rounded-[0.6vw] rounded-tr-none p-[0.5vw]">
      <div className="w-[18vw] h-full">
        <video
          className="w-full h-full object-cover rounded-[0.4vw]"
          controls
          // autoplay='autoplay'
          // loop
        >
          <source src={url} type="video/mp4" />
        </video>
      </div>

      <p
        className="font-md weight-small px-[0.5vw] pt-[0.5vw] break-words"
        dangerouslySetInnerHTML={{
          __html: addLineBreake(replaceFormating(caption)),
        }}
      ></p>
    </div>
  );
}
