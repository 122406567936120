import React, { useEffect, useReducer, useState } from "react";

import { ICONS } from "../../../../assets/icons";
import { images } from "../../../../assets/images";
import "../../styles/Preview.css";

import {
  MessageList,
  ReviewSettingList,
  SegmentReviewList,
} from "../../../../constant/app/campaign/Broadcast";
import { countryList } from "../../../../constant/app/countryList";

import { Modal } from "antd";
import ModalHeader from "../../../../components/commonComponents/modal/header";
import { Button } from "../../../../components/form/Button/Button";
import CountryCode from "../../../../components/commonComponents/countryCode/index";
import TemplatePreview from "../../../../components/commonComponents/templates/TemplatePreview";

import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import {
  INITIAL_STATE,
  templateReducer,
} from "../../../channels/reducers/templateReducer";
import useToggle from "../../../../hooks/useToggle";
import { useLocation } from "react-router-dom";

import { createSendApi } from "../../api/Api";

const BroadcastReview = ({
  broadCasts,
  selectedTemplate,
  selectedRole,
  response,
  response1,
  selectedIndexSegments,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [value1, toggleValue] = useToggle(false);
  const [AddPhoneNumber, setAddPhoneNumber] = useState("");
  const [messageSaved, setMessageSaved] = useState([]);

  const location = useLocation();
  const { currentBrand } = useAspSelector((state) => state.app);

  const [selectedValue, setSelectedValue] = useState([
    { value: "+91", label: "+91" },
  ]);
  const [newPhoneNumber, setNewPhoneNumber] = useState([
    {
      id: 1,
      inputValue: "",
    },
  ]);
  const [send, setSend] = useState({
    mobile_number: null,
    send_from_phone_number: location?.state
      ? broadCasts?.settings?.send_from_phone_number_id
      : selectedRole?.send_from_phone_number_id,
  });

  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );
  const { custom, carousel, authentication } = templateData;

  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
  } = custom;

  const broad = Object?.assign(broadCasts);
  const formattedMobileNumbers = [];
  let valids;

  const handleCancel = () => {
    setSend({
      mobile_number: null,
      send_from_phone_number: location?.state
        ? broadCasts?.settings?.send_from_phone_number_id
        : selectedRole?.send_from_phone_number_id,
    });
    setNewPhoneNumber([
      {
        id: 1,
        inputValue: "",
      },
    ]);
    setMessageSaved(false);
    setIsModalVisible(false);
  };

  newPhoneNumber.forEach((phoneNumber, index) => {
    const countryCode = selectedValue[index]?.value?.match(/\+(\d+)/)[1];
    const formattedNumber = countryCode + phoneNumber.inputValue;

    formattedMobileNumbers.push(parseInt(formattedNumber));
  });

  if (response?.length > 1) {
    const valids1 = response?.[0]?.valid_count;
    const valids2 = response?.[1]?.valid_count;

    valids = valids1 + valids2;
  } else {
    valids = response?.[0]?.valid_count;
  }

  const handleClickSend = () => {
    toggleValue(true);
    const formattedMobileNumbers = [];

    newPhoneNumber.forEach((phoneNumber, index) => {
      const countryCode = selectedValue[index]?.value?.match(/\+(\d+)/)[1];
      const formattedNumber = countryCode + phoneNumber.inputValue;

      formattedMobileNumbers.push(parseInt(formattedNumber));
    });

    const payLoad = {
      brand_id: currentBrand?.brand_id,
      template_id: selectedTemplate?.message_template_id,
      to_number: formattedMobileNumbers,
      from_number: send.send_from_phone_number || send?.from_number,
      subcategory_type: selectedTemplate?.subcategory_type,
    };

    createSendApi(payLoad).then((createCampaignResponse) => {
      if (createCampaignResponse?.status === 200) {
        const statusData = createCampaignResponse?.data?.message_status;
        setMessageSaved(statusData);

        // setSend({
        //   to_number: null,
        //   from_number: location?.state
        //     ? broadCasts?.settings?.send_from_phone_number_id
        //     : selectedRole?.send_from_phone_number_id,
        // });
      }
      toggleValue(false);
    });
  };

  const handleDeleteClick = (id, index) => {
    const updatedNumber = newPhoneNumber?.filter((item) => item?.id !== id);
    setNewPhoneNumber(updatedNumber);
    setMessageSaved((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus.splice(index, 1);
      return newStatus;
    });
  };

  const handleClickModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleAddNumber = () => {
    const maxId =
      newPhoneNumber.length > 0
        ? Math.max(...newPhoneNumber.map((phone) => phone.id))
        : 0;
    const newAddNumber = [
      ...newPhoneNumber,
      {
        id: maxId + 1,
        inputValue: AddPhoneNumber,
      },
    ];
    setNewPhoneNumber(newAddNumber);
    setAddPhoneNumber("");

    setSelectedValue([
      ...selectedValue,
      { id: selectedValue.length, value: "+91" },
    ]);
  };

  const handlePhoneNumberChange = (index, value) => {
    const updatedPhoneNumbers = [...newPhoneNumber];
    updatedPhoneNumbers[index] = {
      ...updatedPhoneNumbers[index],
      inputValue: value,
    };
    setNewPhoneNumber(updatedPhoneNumbers);

    setMessageSaved((prevStatus) => {
      const newStatus = prevStatus ? [...prevStatus] : [];
      newStatus[index] = null;
      return newStatus;
    });
  };

  useEffect(() => {
    templateDispatch({
      type: "update_template",
      data: selectedTemplate,
    });
  }, [selectedTemplate]);

  const segment_total1 = response?.[0]?.segments?.length || 0;
  const segment_total2 = response1
    ?.map((item) => item?.segments?.length)
    ?.reduce((sum, value) => sum + value, 0);

  const capitalizedCategory = selectedTemplate?.category?.toLowerCase()
    ? selectedTemplate?.category?.toLowerCase().charAt(0).toUpperCase() +
      selectedTemplate?.category?.toLowerCase().slice(1)
    : "";

  return (
    <div className="Review-container w-100 flex-row space-between">
      {isModalVisible && <div className="modal-overlay" />}

      <div
        className=" aaaaa flex-column"
        style={{ height: "69vh", overflow: "scroll" }}
      >
        <div className="Review-wrapper">
          <div className="Review-first-container flex-row w-100 space-between align-center pointer ">
            <p>Broadcast Settings</p>
            {/* <img src={ICONS?.edit} alt="edit" onClick={handleClick} /> */}
          </div>
          <div className="Review-List  flex-row align-center">
            <div className="w-50 pt-10 pb-10">
              {ReviewSettingList?.slice(0, 4)?.map((data) => (
                <p key={data?.id}>{data?.name}</p>
              ))}
            </div>
            <div className="Review-left flex-column">
              <span>
                {broad?.name
                  ? broad?.name.charAt(0).toUpperCase() + broad?.name.slice(1)
                  : ""}
              </span>

              <div className="flex-row align-center pl-10">
                <img src={ICONS?.whatsApp} alt="whatsappLogo" />

                {broad || !location?.state?.item ? (
                  <span className="Whatsapp_name p-10">
                    {broad?.settings?.channel}
                  </span>
                ) : (
                  <span className="flex-row align-center pl-10 ">
                    {broadCasts?.settings?.channel}
                  </span>
                )}
              </div>
              <span>
                {broad?.settings?.send_from_phone_name}-
                <span className="preview-segments">
                  <span className="pr-5">{broad.settings?.country_code}</span>
                  {broad.settings?.send_from_phone_number}
                </span>
              </span>
              <span>{broad?.settings?.send_later_dt}</span>
            </div>
          </div>
        </div>

        <div className="Review mt-10">
          <div className="Review-first-container flex-row w-100 space-between align-center pointer ">
            <p>Broadcast Segment</p>
            {/* <img src={ICONS?.edit} alt="edit" onClick={handleSegmentClick} /> */}
          </div>
          <div className="Review-List flex-row align-center">
            <div className="w-50">
              {SegmentReviewList?.slice(0, 4)?.map((data) => (
                <p key={data?.id}>{data?.content} </p>
              ))}
            </div>
            <div className="Review-left w-50 flex-column">
              <span className="broadcast-preview-segments">
                {response?.length}
              </span>
              <span className="broadcast-preview-segments">
                {valids}
                <span className="preview-segments">Contacts</span>
              </span>
              <span className="broadcast-preview-segments">
                {broad?.settings?.segment_mode
                  ? broad.settings.segment_mode.charAt(0).toUpperCase() +
                    broad.settings.segment_mode.slice(1)
                  : ""}
              </span>

              <span className="broadcast-preview-segments">
                {!selectedIndexSegments === "merge" ||
                  (selectedIndexSegments === "individual" && segment_total2)}
                {!selectedIndexSegments === "individual" ||
                  (selectedIndexSegments === "merge" && segment_total1)}

                <span className="preview-segments">Segments</span>
              </span>
            </div>
          </div>
        </div>
        <div className="Review mt-10 ">
          <div className="Review-first-container flex-row  space-between align-center pointer ">
            <p>Broadcast Message</p>
            {/* <img src={ICONS?.edit} alt="edit" onClick={handleMessageClick} /> */}
          </div>
          <div className="Review-List  flex-row align-center">
            <div className="w-50">
              {MessageList?.slice(0, 1)?.map((data) => (
                <p key={data?.id}>{data?.name}</p>
              ))}
            </div>
            <div className="Review-content flex-row align-center mx-3 p-10">
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "120px",
                  padding: "10p 0px",
                }}
              >
                {broad &&
                  location?.state?.item &&
                  broad?.settings?.template_name}
                {selectedTemplate && selectedTemplate?.name}
              </span>
              <span
                className="status-color flex align-center justify-center"
                style={{
                  color:
                    selectedTemplate?.category === "MARKETING"
                      ? "var(--statusFont)"
                      : "#f6a723",
                  background:
                    selectedTemplate?.category === "MARKETING"
                      ? "var(--statusBg)"
                      : "#FFFBEB",
                }}
              >
                {capitalizedCategory}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="MessagePreview w-35 h-50">
        <div className="TestMessage flex-row">
          <p className="flex-row align-center">Message Preview</p>

          <button
            onClick={handleClickModal}
            className="flex-row align-center mb-10"
          >
            <img src={ICONS?.send} alt="send" />
            Test Message
          </button>

          <Modal
            open={isModalVisible}
            footer={null}
            className={"broadcast-modal"}
            closable={false}
            centered={true}
            onCancel={handleCancel}
          >
            <ModalHeader
              handleCancel={handleCancel}
              title="Send test message"
              content="Add phone numbers to send test messages."
            />
            <div className="main-numbers-test flex-column  pointer ">
              {newPhoneNumber?.map((phoneNumber, index) => {
                return (
                  <div
                    className=" flex-row align-center pl-10 mt-10"
                    key={phoneNumber?.id}
                  >
                    <CountryCode
                      value={"+91"}
                      options={countryList}
                      onChange={(val) => {
                        const newSelectedValue = [...selectedValue];
                        newSelectedValue[index] = val;
                        setSelectedValue(newSelectedValue);
                      }}
                      className="!min-w-[5vw]"
                    />

                    <div className="number-input pr-10 ml-10 flex-row align-center">
                      <input
                        autoComplete="off"
                        name={`phoneNumber-${phoneNumber.id}`}
                        placeholder="EX:- 0000 000 000"
                        value={phoneNumber?.inputValue}
                        onChange={(e) => {
                          const inputValue = e.target.value
                            .replace(/\D/g, "")
                            .substring(0, 10);
                          handlePhoneNumberChange(index, inputValue);
                        }}
                        style={{
                          padding: "3px 10px",
                          width: "17vw",
                          height: "3.5vh",
                          borderRadius: "6px",
                        }}
                        className="number-input-inner"
                      />
                      {messageSaved[index] &&
                        messageSaved[index]?.status?.meta_response?.status && (
                          <div>
                            {messageSaved[index]?.status?.meta_response
                              ?.status === false && (
                              <img
                                src={ICONS.messageFailed}
                                alt="messageFailed"
                              />
                            )}
                            {messageSaved[index]?.status?.meta_response
                              ?.status === true && (
                              <img src={ICONS.messageSent} alt="messageSent" />
                            )}
                          </div>
                        )}
                    </div>
                    {newPhoneNumber?.length > 1 && (
                      <img
                        src={ICONS?.RedDeleteIcon}
                        alt="delete"
                        onClick={() => handleDeleteClick(phoneNumber.id)}
                      />
                    )}
                  </div>
                );
              })}

              {newPhoneNumber?.length < 5 && (
                <div
                  className="flex-row align-center p-10"
                  style={{ cursor: "pointer" }}
                  onClick={handleAddNumber}
                  data-testid="AddButton"
                >
                  <img src={ICONS?.addPlusIcon} alt="plus icon" />
                  <span className="footer-cancel pointer">Add Number</span>
                </div>
              )}
            </div>
            <div className="footer-test-message flex align-center space-between">
              <span className="">Max limit 5 numbers</span>
              <div className="flex-row ">
                <button
                  className="footer-cancel"
                  onClick={handleCancel}
                  data-testid="cancel-click"
                >
                  Cancel
                </button>
                <Button
                  size={"medium"}
                  label={"Send"}
                  loading={value1}
                  onClick={handleClickSend}
                  data-testid="send-click"
                />
              </div>
            </div>
          </Modal>
        </div>
        <div className="w-full min-h-[65vh] max-h-[70vh] h-full">
          <div className="w-[18vw] min-h-[57vh] max-h-[59vh] mx-auto border-[0.4vw] border-[var(--white)] rounded-[2vw] my-[0.7vw] shadow-[0px_4px_30px_0px_#2D303620]">
            <div className="h-[6vh] flex items-center gap-[0.5vw] bg-[#077C70] rounded-t-[1.5vw] px-[0.6vw]">
              <div className="size-[2.1vw] bg-[var(--white)] rounded-full flex items-center justify-center font-xs">
                Logo
              </div>
              <div className="text-[var(--white)]">
                <h3 className="font-md weight-bold capitalize leading-[1.2vw]">
                  {broadCasts?.settings?.send_from_phone_name ||
                    selectedRole?.send_from_phone_name}
                </h3>
                <span className="font-md weight-bold capitalize flex-row leading-[1.2vw]">
                  {broadCasts?.settings?.country_code ||
                    selectedRole?.country_code}
                  <p className="pl-5">
                    {broadCasts?.settings?.send_from_phone_number ||
                      selectedRole?.send_from_phone_number}
                  </p>
                </span>
              </div>
            </div>
            <TemplatePreview
              header={header}
              headerVariable={headerVariable}
              body={body}
              bodyVariables={bodyVariables}
              footer={footer}
              buttons={buttons}
              buttonsOrder={buttonsOrder}
              ctaButtons={ctaButtons}
              customButtons={customButtons}
              carousel={carousel}
              authentication={authentication}
              category={selectedTemplate?.subcategory_type}
              data={selectedTemplate}
              className={"rounded-none min-h-[45vh] max-h-[45vh]"}
              drawerType="small"
            />
            <img
              src={images?.TemplatePreviewBottom}
              alt="send msg"
              className="w-[17.35vw] h-[2.3vw] rounded-b-[1.5vw]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastReview;
