// import moment from "moment";
// import React from "react";
// import Chart from "react-apexcharts";

// const AreaChart = ({ OverviewList = {} }) => {
//   const sortedData =
//     OverviewList?.bounced?.sort(
//       (a, b) => new Date(a.date) - new Date(b.date)
//     ) || [];

//   // Generate x-axis labels
//   const labels =
//     sortedData.length > 25
//       ? sortedData
//           .filter((_, index) => index % 4 === 0)
//           .map((item) => moment(item.date).format("DD MMM"))
//       : sortedData.length > 7
//       ? sortedData
//           .filter((_, index) => index % 2 === 0)
//           .map((item) => moment(item.date).format("DD MMM"))
//       : sortedData.slice(-7).map((item) => moment(item.date).format("DD MMM"));

//   // Helper function to safely get counts for a specific key
//   const getCounts = (key) =>
//     OverviewList?.[key]?.slice(0, labels.length).map((item) => item.count) ||
//     [];

//   // Chart options with hidden x-axis and y-axis
//   const chartOptions = {
//     chart: {
//       type: "area",
//       height: 70,
//       zoom: { enabled: false },
//       toolbar: { show: false },
//     },
//     dataLabels: { enabled: false },
//     stroke: { curve: "smooth" },
//     tooltip: {},
//     fill: {
//       type: "gradient",
//       gradient: {
//         shadeIntensity: 1,
//         opacityFrom: 0.3,
//         opacityTo: 0.7,
//         stops: [0, 90, 100],
//       },
//     },
//     grid: {
//       show: true,
//       borderColor: "#f1f1f1",
//     },
//     xaxis: {
//       //   categories: labels, // Use dynamic labels for X-axis
//       labels: { show: false },
//       axisBorder: { show: false },
//       axisTicks: { show: false },
//     },
//     yaxis: {
//       labels: { show: false },
//       axisBorder: { show: false },
//       axisTicks: { show: false },
//     },
//   };

//   // Define each series for the chart
//   const dataKeys = ["bounced", "delivered", "read", "sent"];
//   const series = dataKeys.map((key) => ({
//     name: key.charAt(0).toUpperCase() + key.slice(1),
//     data: getCounts(key),
//   }));

//   return (
//     <div className="chart-container">
//       <Chart options={chartOptions} series={series} type="area" height={70} />
//     </div>
//   );
// };

// export default AreaChart;

import React, { useState } from "react";
import Chart from "react-apexcharts";

const AreaChart = ({ data, color, name }) => {
  const options = {
    chart: {
      type: "area",
      height: 0,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      padding: {
        left: 0,
        right: 0,
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      padding: {
        left: 0,
        right: 0,
      },
    },
  
    fill: {
      colors: [color],
    },
    grid: {
      show: false,
    },
  };

  const series = [
    {
      name: name,
      data: data.map((item) => item?.count),
    },
  ];

  return (
    <>
      {data?.length > 0 ? (
        <Chart
          options={options}
          series={series}
          type="area"
          width="100%"
          height={0}
        />
      ) : (
        <div
          className="flex items-center justify-center mb-20"
          style={{ border: `1px solid ${color}` }}
        ></div>
      )}
    </>
  );
};

export default AreaChart;
