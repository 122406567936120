import React from "react";

// assets
import { ICONS } from "assets/icons";
import Avatar from "assets/customSVG/Avatar";

// helper
import { timeSince } from "helper/timeConversion";
import { abbrNum } from "helper/abbrNum";
import { replaceFormating } from "../../helper";

// components
import ConversationStar from "../conversation/ConversationStar";
import ConversationClose from "../conversation/ConversationClose";
import {
  FEATURE_FLAG_ADD_TO_STAR,
  FEATURE_FLAG_MANUALLY_CLOSE_CONVERSATION,
} from "../../constants/FeatureFlag";

export default function CustomerPhoneNumberCard({
  item,
  selectedPerson,
  onSelectPerson,
  onConversationStarChange,
  onCloseConversation,
}) {
  const {
    from_number,
    to_number,
    message,
    body,
    updated_at,
    unread_count,
    first_name,
    last_name,
    profile_name,
    is_customer_msg,
    status,
  } = item;

  // const formatDate = moment(updated_at).format('YYYY-MM-DD Hh:mm:ss');
  const newdate = timeSince(updated_at, "single", false);

  const name =
    first_name && last_name
      ? `${first_name} ${last_name}`
      : first_name
      ? first_name
      : last_name
      ? last_name
      : profile_name && profile_name;

  return (
    <div
      key={to_number}
      className={`w-full h-[3.8vw] pl-[0.6vw] pr-[0.2vw] border-b-[0.08vw] border-[var(--border-50)] flex items-center cursor-pointer transition-colors ease-linear duration-200 hover:bg-[#F4F6F8] mt-[0.3vw] gap-x-[0.6vw] group ${
        selectedPerson?.to_number === item?.to_number
          ? "bg-[var(--BG-50)]"
          : "bg-transparent"
      }`}
    >
      <div
        className="flex items-center gap-x-[0.8vw]"
        onClick={() => {
          onSelectPerson(item);
        }}
        data-testid="singlePerson"
      >
        <div className="size-[2.5vw] relative top-0 left-0">
          <div className="size-[2.5vw] rounded-full flex items-center justify-center font-largest weight-medium">
            <Avatar color="#ffffff" />
          </div>
          <img
            src={ICONS?.whatsApp}
            alt="inbox"
            className="w-[1.2vw] h-[1.2vw] border-[0.2vw] border-[var(--white)] rounded-full z-20 absolute -bottom-[0vw] -right-[0.4vw]"
          />
        </div>
        <div className="min-w-[11.5vw] flex flex-col gap-y-[0.35vw]">
          <span className="w-full font-md weight-bold text-[var(--contentText)]">
            {name ?? to_number}
          </span>
          <span
            className="w-full font-sm weight-medium text-[var(--font-600)] text-ellipsis line-clamp-1"
            dangerouslySetInnerHTML={{
              __html:
                replaceFormating(message?.text?.body) ||
                replaceFormating(message?.body),
            }}
          >
          </span>
        </div>
      </div>
      <div className="min-w-[2.9vw] h-full overflow-hidden relative top-0 left-0">
        <div className="w-full h-full flex flex-col items-center gap-y-[0.3vw] py-[0.758vw] absolute top-0 left-0 opacity-100 transition-opacity ease-linear duration-200">
          <span className="text-[0.55vw] weight-semibold text-[var(--font-400)]">
            {newdate}
          </span>
          {unread_count > 0 && (
            <span className="max-w-full h-[1.14vw] px-[0.4vw] font-xs weight-medium text-[var(--white)] bg-[var(--primary)] flex-center rounded-full">
              {abbrNum(unread_count, 2)}
            </span>
          )}
        </div>
        {(FEATURE_FLAG_MANUALLY_CLOSE_CONVERSATION ||
          FEATURE_FLAG_ADD_TO_STAR) && (
          <div className="w-full h-full flex flex-col items-center justify-center py-[0.2vw] gap-y-[0.5vw] bg-[var(--BG-25)] absolute top-0 left-0 opacity-0 group-hover:opacity-100  transition-opacity ease-linear duration-200">
            {FEATURE_FLAG_MANUALLY_CLOSE_CONVERSATION && (
              <ConversationClose
                className={"w-[0.9vw] h-[0.9vw]"}
                onChange={onCloseConversation}
                data={item}
              />
            )}
            {FEATURE_FLAG_ADD_TO_STAR && (
              <ConversationStar
                data={item}
                width={"0.95vw"}
                height={"0.95vw"}
                onChange={onConversationStarChange}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
