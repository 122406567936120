import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Error from "../modules/error/Index";

// auth
import Login from "../modules/auth/pages/Login";
import ForgotPassword from "../modules/auth/pages/ForgotPassword";
import ResetPassword from "../modules/auth/pages/ResetPassword";
import AuthLayout from "../layouts/authLayout/Index";
import Stepper from "../modules/auth/pages/Stepper";
import OnBoarding from "../modules/auth/pages/OnBoarding";
import VerifiyEmail from "../modules/auth/pages/VerifyEmail";

import Accounts from "../modules/auth/components/Accounts";
import Resend from "../modules/auth/components/Resend";
import SignUp from "../modules/auth/pages/SignUp";

// app
import AppLayout from "../layouts/appLayout/Index";
import Home from "../modules/home/pages/Home";

// campaign
import ContactApi from "../modules/campaign/components/ApiCampaign/ContactApi";
// import TaskRight from "../modules/Task/pages/TaskRightLayout";

// channels
import ListOfChannels from "../modules/channels/page/Channels";
import Whatsapp from "../modules/channels/page/whatsapp/Whatsapp";
// messageTemplate
import MessageTemplate from "../modules/channels/page/whatsapp/messageTemplate/MessageTemplate";
import CreateTemplateWA from "../modules/channels/components/templates/CreateTemplateWA";
// import TemplateDetails from '../modules/channels/components/templates/TemplateDetails';
import TemplateDetails from "../modules/channels/page/whatsapp/TemplateDetails";

// phoneNumbers
import PhoneNumberss from "../modules/channels/page/whatsapp/phoneNumbers/Index";
// insights
import Insights from "../modules/channels/page/whatsapp/insights/Insights";

import Broadcast from "../modules/campaign/components/BroadcastSetting/BroadCast";
import BroadcastSetting from "../modules/campaign/components/BroadcastSetting/BroadcastSetting";
import SegmentList from "../modules/campaign/components/BroadcastSetting/BroadcastView/SegmentList";

// calendar
import Calendar from "../modules/calendar/pages/calendar";

//settings
import Settings from "../modules/settings/pages/Settings";
import BrandSettings from "../modules/settings/pages/BrandSettings";

// inbox
import Inbox from "../modules/inbox/pages/Inbox";
import Homes from "../modules/chart/pages/Homes";

// crm
import CrmSidebar from "../modules/crm/pages/CrmHome";
import CrmHome from "../modules/crm/components/main";
import SingleContact from "../modules/crm/pages/SingleContact";

// Account Settings
import AccountsHome from "../modules/accounts/pages/home/Home";
import AccountsSettings from "../modules/accounts/pages/Settings";
import AccountsOverview from "../modules/accounts/pages/home/Overview";
import AccountsList from "../modules/accounts/pages/home/AccountsList";
import SingleAccountDetails from "../modules/accounts/pages/home/SingleAccountDetails";
import BrandsList from "../modules/accounts/pages/home/BrandsList";
import SingleBrandDetails from "../modules/accounts/pages/home/SingleBrandDetails";

// new campaigns
import CampaignsRoot from "../modules/campaigns/pages";
import Campaigns from "../modules/campaigns/pages/Campaigns";
import CampaignsList from "../modules/campaigns/pages/CampaignsList";
import SingleCampaign from "../modules/campaigns/pages/SingleCampaign";
import Overview from "../modules/campaigns/pages/Overview";
import Execution from "../modules/campaigns/pages/Execution";
import Sequence from "../modules/campaigns/pages/Sequence";

function Index() {
  const TOKEN = localStorage.getItem("authToken");
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={<Navigate to={TOKEN !== null ? "/Accounts" : "/login"} />}
        />
        <Route path="/verify-email" element={<VerifiyEmail />} />

        <Route path="/404" element={<Error />} />
        <Route element={<AuthLayout />}>
          {/* <Route path='/stepper' element={<Stepper />} /> */}

          {/* self serve */}
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/on-boarding" element={<OnBoarding />} />

          <Route path="/resend" element={<Resend />} />

          <Route path="/login" element={<Login />} />
          <Route path="/Accounts" element={<Accounts />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route path="/user/home" element={<Home />}></Route>

          {/* <Route path="/user/campaign" element={<CampaignsRoot />}>
            <Route path=":campaign_type">
              <Route index element={<CampaignList />} />
              <Route path=":id" element={<Broadcast />} />
              <Route path="settings/:id" element={<BroadcastSetting />} />
              <Route path="segment_list/:id" element={<SegmentList />} />
            </Route>
            <Route path="perpetual">
              <Route index element={<Api />} />
              <Route path=":id" element={<ContactApi />} />
            </Route>

            <Route path="ContactApi/:id" element={<ContactApi />} />

            <Route path="Broadcast/:id" element={<Broadcast />} />
            <Route path="BroadcastSetting/:id" element={<BroadcastSetting />} />
            <Route path="SegmentList/:id" element={<SegmentList />} />
          </Route> */}

          <Route path="/user/calendar/" element={<Calendar />} />
          <Route path="/user/settings" element={<Settings />}>
            <Route index path="brand-settings" element={<BrandSettings />} />
          </Route>
          <Route path="/user/inbox" element={<Inbox />} />
          <Route path="/user/chart" element={<Homes />} />

          {/* channels */}
          <Route path="/user/channels" element={<ListOfChannels />}>
            <Route path="whatsapp" element={<Whatsapp />}>
              <Route path="phonenumbers" element={<PhoneNumberss />} />
              <Route path="message-templates" element={<MessageTemplate />} />
              <Route path="insights" element={<Insights />} />
              {/* Template create, edit and duplicate */}
              <Route path=":template">
                <Route index element={<CreateTemplateWA />} />
                <Route path="template-details" element={<TemplateDetails />} />
              </Route>
            </Route>
          </Route>

          {/* CRM */}
          <Route path="/user/crm/" element={<CrmSidebar />}>
            <Route index element={<CrmHome />} />
            <Route path=":id" element={<SingleContact />} />
          </Route>

          {/* Account Details */}
          <Route path="/profile">
            <Route path="account" element={<AccountsHome />}>
              <Route path="overview" element={<AccountsOverview />}>
                <Route index element={<AccountsList />} />
                <Route path=":account_id">
                  <Route index element={<BrandsList />} />
                  <Route path="edit" element={<SingleAccountDetails />} />
                  <Route
                    path=":brand_id/edit"
                    element={<SingleBrandDetails />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path="settings" element={<AccountsSettings />} />
          </Route>

          {/* campaigns */}
          <Route path="/user/campaign" element={<CampaignsRoot />}>
            <Route path=":goalType" element={<Campaigns />}>
              <Route index element={<CampaignsList />} />
              {/* <Route path=":id" element={<Broadcast />} /> */}
              <Route path="settings/:id" element={<BroadcastSetting />} />
              <Route path="segment_list/:id" element={<SegmentList />} />
              <Route path=":id" element={<ContactApi />} />
            </Route>

            <Route path="view/:id" element={<SingleCampaign />}>
              <Route path="overview" element={<Overview />} />
              <Route path="execution" element={<Execution />} />
            </Route>
          </Route>
          {/* <Route
            path='/user/campaigns/:type/:id/sequence'
            element={<Sequence />}
          /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Index;
