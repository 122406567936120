import React from 'react';

// helper
import { getLanguageName } from '../../../helper/setLanguageCode';
import { capitalizeFirstLetter } from '../../../helper/capitalizeFirstLetter';

// components
import TemplateStatus from './TemplateStatus';

export default function TemplateCard({
  data,
  selected,
  className,
  handlePreviewSelect,
  useTemplate,
  previewSelect,
  useThisTemplate,
}) {
  return (
    <div
      className={`w-full min-h-[8vh] flex items-center justify-between gap-[1vw] px-[0.5vw] py-[0.25vw] border-[0.08vw] border-[var(--border-50)] rounded-[0.6vw] transition-colors ease-linear duration-300 ${className} ${
        selected ? "bg-[var(--BG-25)]" : "bg-transparent"
      }`}
    >
      <div
        className="w-[85%] pl-[0.5vw] flex flex-col gap-[0.5vw] cursor-pointer"
        onClick={() => {
          handlePreviewSelect(data);
        }}
        data-testid={previewSelect}
      >
        <div className="flex items-center gap-[0.5vw]">
          <div className="max-w-[18vw] overflow-hidden truncate font-md weight-bold text-[var(--content)] capitalize">
            {data?.name}
          </div>
          <div
            className={`px-[0.3vw] py-[0.1vw] font-xs weight-semibold rounded-[0.25vw] capitalize ${
              data?.category === "MARKETING"
                ? "bg-[var(--statusBg)] text-[var(--statusFont)]"
                : "bg-[#FFFBEB] text-[#F6A723]"
            }`}
          >
            {capitalizeFirstLetter(data?.category)}
          </div>
          <div className="px-[0.3vw] py-[0.1vw] font-xs weight-semibold bg-[#E7F4FC] text-[#4AACEA] rounded-[0.25vw] capitalize">
            {getLanguageName(data?.language)}
          </div>
        </div>
        <div className="w-fit py-[0.2vw] font-md weight-medium  rounded-[0.25vw] capitalize">
          <TemplateStatus id={data?.name} status={data?.status} />
        </div>
      </div>
      <button
        className="w-[15%] px-[1vw] py-[0.15vw] font-md weight-semibold bg-[var(--primary)] text-[var(--white)] rounded-[0.37vw]"
        onClick={() => {
          if (handlePreviewSelect) handlePreviewSelect(data);
          if (useTemplate) useTemplate();
        }}
        data-testid={useThisTemplate}
      >
        Use
      </button>
    </div>
  );
}
