import React, { useCallback } from "react";

// helper
import { dateConverter } from "../../../../helper/dateConverter";

// components
import ConversationCard from "./ConversationCard";
import { replaceFormating } from "../../helper";
import ImageMessagePreview from "./ImageMessagePreview";
import VideoMessagePreview from "./VideoMessagePreview";
import DocumentMessagePreview from "./DocumentMessagePreview";
import { addLineBreake } from "../../helper";
import CarouselCardPreview from "../../../../components/commonComponents/templates/CarouselCardPreview";

export default function RightSideConversationCard({ data }) {
  const d = dateConverter(data?.created_at, false);

  const objData = !Array?.isArray(data?.message) && data?.message;

  const parseObj = (obj) => {
    if (typeof obj === "string") return JSON.parse(obj);

    return "";
  };

  return (
    <div>
      <div className="relative w-auto max-w-[26vw] min-h-[3.5vh] bg-[#D8FBD2] rounded-[0.6vw] rounded-tr-none p-[0.5vw]">
        <div className="w-0 h-0 bg-transparent border-l-[0.5vw] border-r-[0.5vw] border-b-[1vw] border-l-transparent border-r-transparent border-b-[#D8FBD2] absolute -right-[0.5vw] top-0 rotate-180" />
        {["text", "image", "video", "document"]?.includes(
          data?.message?.type
        ) ? (
          data?.message?.type === "image" ? (
            <ImageMessagePreview
              url={data?.message?.image?.link}
              caption={data?.message?.image?.caption}
            />
          ) : data?.message?.type === "video" ? (
            <VideoMessagePreview
              url={data?.message?.video?.link}
              caption={data?.message?.video?.caption}
            />
          ) : data?.message?.type === "document" ? (
            <DocumentMessagePreview
              url={data?.message?.document?.link}
              caption={data?.message?.document?.caption}
            />
          ) : (
            <p
              className="font-md weight-small px-[0.5vw] break-word"
              dangerouslySetInnerHTML={{
                __html: addLineBreake(
                  replaceFormating(data?.message?.text?.body)
                ),
              }}
            ></p>
          )
        ) : (
          <>
            {data?.is_customer_msg === false &&
            Array.isArray(data?.message?.template || data?.message) ? (
              <>
                <ConversationCard data={data} />
              </>
            ) : parseObj(data?.message)?.text?.body ||
              parseObj(data?.message)?.body ? (
              <div
                className={`w-full font-md weight-medium text-[var(--contentText)] rounded-[0.6vw] rounded-tl-none py-[0vw]`}
                dangerouslySetInnerHTML={{
                  __html: replaceFormating(
                    parseObj(data?.message)?.text?.body ||
                      parseObj(data?.message)?.body
                  ),
                }}
              >
                {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
                {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
              </div>
            ) : (
              <div
                className={`w-full font-md weight-medium text-[var(--contentText)] rounded-[0.6vw] rounded-tl-none py-[0vw]`}
                dangerouslySetInnerHTML={{
                  __html: replaceFormating(
                    data?.message?.text?.body || data?.message?.body
                  ),
                }}
              >
                {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
                {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex justify-end text-[0.55vw] weight-semibold text-[var(--font-400)] mt-[0.3vw]">
        <p>{d?.timeWithMeridiem}</p>
      </div>
    </div>
  );
}
