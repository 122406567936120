import React from "react";
import { Bar, Line } from "react-chartjs-2";

import FilterHome from "../../../../components/commonComponents/filterHome/FilterHome";
import { ICONS } from "../../../../assets/icons";
import {
  broadcastPerformanceFilter,
  HomeFilterCampaign,
  HomeFilterList,
} from "../../../../constant/app/Filter/Filter";

// ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";

// Register necessary components from Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const Performance = ({ performanceList }) => {
  // const sortedData = performanceList?.blocked?.sort(
  //   (a, b) => new Date(a.date) - new Date(b.date)
  // );

  const hasData = ["blocked", "leads", "reactions", "clicks"]?.some(
    (key) => performanceList?.[key]?.length > 0
  );

  const availableKey = ["blocked", "leads", "reactions", "clicks"].find(
    (key) => performanceList?.[key]
  );

  const sortedData = availableKey
    ? performanceList[availableKey].sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      )
    : [];

  const labels =
    sortedData?.length > 25
      ? sortedData
          .filter((_, index) => index % 4 === 0)
          .map((item) => moment(item.date).format("DD MMM"))
      : sortedData?.length > 7
      ? sortedData
          .filter((_, index) => index % 2 === 0)
          .map((item) => moment(item.date).format("DD MMM"))
      : sortedData?.slice(-7).map((item) => moment(item.date).format("DD MMM"));

  const blockedCounts = performanceList?.blocked
    ?.slice(0, 7)
    .map((item) => item.count);
  const clicksCounts = performanceList?.clicks
    ?.slice(0, 7)
    .map((item) => item.count);
  const leadsCounts = performanceList?.leads
    ?.slice(0, 7)
    .map((item) => item.count);
  const reactionCounts = performanceList?.reactions
    ?.slice(0, 7)
    .map((item) => item.count);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Blocked",
        data: "blockedCounts",
        fill: false,
        backgroundColor: "#FF543E",
        borderColor: "#FF543E",
        borderWidth: 2,
      },
      {
        label: "Clicks",
        data: clicksCounts,
        fill: false,
        backgroundColor: "#9747FF",
        borderColor: "#9747FF",
        borderWidth: 2,
      },
      {
        label: "Leads",
        data: leadsCounts,
        fill: false,
        backgroundColor: "#3E93FF",
        borderColor: "#3E93FF",
        borderWidth: 2,
      },
      {
        label: "Reactions",
        data: reactionCounts,
        fill: false,
        backgroundColor: "#F6A723",
        borderColor: "#F6A723",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          padding: 20,
        },
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,

        position: "nearest",
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.raw; // Using raw data here
            return ` ${label}:  ${value || 0}`; // Show '0' for missing values
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 16,
            weight: "500",
            color: "var(--font-600)",
          },
          maxTicksLimit: 6,
        },
        grid: {
          color: "#E8E8EA", // Change the X-axis grid line color
          borderColor: "#E8E8EA", // Change the X-axis line color
        },
      },
      y: {
        beginAtZero: true,

        ticks: {
          // stepSize: 200,
          callback: function (value) {
            if (value >= 1000) {
              return `${value / 1000}k`;
            }
            return value;
          },
          font: {
            size: 16,
            weight: "500",
            color: "var(--font-600)",
          },
          padding: 20,
        },
        grid: {
          color: "#E8E8EA", // Change the X-axis grid line color
          borderColor: "#E8E8EA", // Change the X-axis line color
        },
      },
    },
    layout: {
      padding: {
        left: 20,
      },
    },
  };

  return (
    <div className="bg-white rounded-[1vw]  w-100">
      <div className="flex-row justify-between items-center pt-20 prl-10 ml-10 mr-10">
        <p className="flex-row items-center chart-headers">
          Performance
          {/* <span className="ml-2">
            <img src={ICONS?.homeDownload} className="w-[18px] h-[18px]" />
          </span> */}
        </p>
      </div>

      <div style={{ height: "28vh" }} className="mr-[20px]">
        {hasData ? (
          <Line data={data} options={options} />
        ) : (
          <div className="h-full flex flex-col items-center justify-center">
            <img src={ICONS?.noChartData} alt="noChartData" />
            <p className="text-[#616874]">There’s no data yet</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Performance;
