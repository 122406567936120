import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Popover, Skeleton, Tooltip } from "antd";
import Calendar from "../../../../assets/customSVG/navCalendar";

// helper
import { cn } from "../../../../helper/cn";
import { dateConverter } from "../../../../helper/dateConverter";

// constants
import { CAMPAIGN_STATUS } from "../../constants/campaigns";

// components
import CheckBoxSqure from "../../../../components/commonComponents/checkbox/CheckBoxSqure";
import Loader from "../../../../components/commonComponents/Loader/Index";
import InfiniteScroll from "react-infinite-scroll-component";
import { ICONS } from "../../../../assets/icons";
import CreateCampaignModal from "../../../../components/commonComponents/campaigns/CreateCampaignModal";
import { campaignDelete } from "../../../campaign/api/Api";
import { useAspSelector } from "../../../../test/jest-redux-hooks";
import useToggle from "../../../../hooks/useToggle";
import NewAlert from "../../../../components/commonComponents/modal/alert/NewAlert";
import NoData from "../../../../components/commonComponents/Empty/NoData";

const campaign_type_id = {
  2: "Time Specified",
  1: "Unspecified",
  3: "Perpetual",
};

const campaign_goal_type_id = {
  1: "Engagement",
  2: "Leads",
  3: "Awareness",
};

export default function List({
  isLoading,
  list,
  count,
  loadNextPage,
  isLoadingSkeleton,
  searchKey,
  onCreate,
  selectedTypes,
  isModalOpen,
  setIsModalOpen,
  setSearchKey,
  clearType,
  clearStatus,
  statusTrue,
}) {
  const navigate = useNavigate();
  const listContainerRef = useRef(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [startPopoverVisible, setStartPopoverVisible] = useState({});
  const [discardAlertOpen, setDiscardAlertOpen] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [campaignId, setCampaignId] = useState(null);
  const [deleteLoader, setDeleteLoader] = useToggle(false);
  const { currentBrand } = useAspSelector((state) => state.app);

  const togglePopoverVisibility = (id) => {
    setStartPopoverVisible((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleEditCampaign = (e, item) => {
    e.preventDefault();
    setSelectedCampaign(item);
    setIsModalOpen(true);
    setStartPopoverVisible({});
  };

  const handleDeleteCampaign = (e, item) => {
    e.preventDefault();
    setDiscardAlertOpen(true);
    setCampaignName(item?.name);
    setCampaignId(item?.id);
    setStartPopoverVisible({});
  };

  const deleteCampaign = (item) => {
    setDeleteLoader(true);
    setDiscardAlertOpen(true);
    campaignDelete(campaignId, currentBrand?.brand_id).then((res) => {
      if (res?.status === 200) {
        setDeleteLoader(false);
        window.location.reload();
      }
    });
  };

  const handleNavigateCampaigns = (l) => {
    if (l?.campaign_type_id && l?.campaign_type_id === 3) {
      navigate(`/user/campaign/perpetual/${l?.id}`, {
        state: { item: l },
      });
    } else if (l?.status !== 10) {
      navigate(`/user/campaign/view/${l?.id}/overview`, {
        state: { data: l },
      });
    } else {
      navigate(`/user/campaign/view/${l?.id}/execution`, {
        state: { data: l },
      });
    }
  };

  // styles
  const headerStyle =
    "text-sm weight-semibold text-[var(--font-600)] text-left";
  const bodyStyle =
    "text-sm weight-medium text-[var(--font-600)] text-left pl-1";
  const dotStyle =
    'size-1.5 contents-[""] rounded-full group-hove/dotsr:!bg-[var(--BG-100)]';

  return (
    <div className="w-full relative h-full overflow-hidden">
      <Skeleton loading={isLoadingSkeleton} active paragraph={{ rows: 6 }}>
        {list?.length > 0 && (
          <div className="w-full h-14 sticky top-0 flex items-center pr-1">
            <div className={cn("new-header2", "w-[30%]")}>Campaign Name</div>
            <div className={cn("new-header2", "w-[15%]")}>Status</div>
            <div className={cn("new-header2", "w-[13%]")}>Channel</div>
            <div className={cn("new-header2", "w-[17%]")}>Campaign Type</div>
            <div className={cn("new-header2", "w-[15%]")}>Goal Type</div>
            <div className={cn("new-header2", "w-[15%]")}>Progress</div>
            {location?.pathname !== "/user/campaign/perpetual" && (
              <div className={cn("new-header2", "w-[10%]")}>Actions</div>
            )}
          </div>
        )}
        <div
          className={`min-w-full h-full min-h-[60vh] overflow-hidden overflow-y-scroll`}
          id="scrollableDiv"
          ref={listContainerRef}
        >
          <InfiniteScroll
            dataLength={list?.length || 0}
            next={() => {
              loadNextPage();
            }}
            hasMore={list?.length < count}
            scrollableTarget="scrollableDiv"
            height={isLoading ? "58vh" : "63vh"}
            className="!pr-1 max-[1600px]:pb-24 min-[1600px]:pb-24 listScroll"
          >
            {isLoading && (
              <div className="w-full h-[5vh] flex items-center justify-center bg-white rounded-lg">
                <Loader Width={30} Height={30} />
              </div>
            )}
            {list?.length > 0 ? (
              <>
                {list?.map((l) => {
                  const date = dateConverter(l?.created_at);
                  const status = CAMPAIGN_STATUS[l?.status];
                  return (
                    <div
                      key={l?.id}
                      className="w-full odd:bg-gray-50 my-1 py-4 flex items-center rounded-lg pointer"
                    >
                      <div
                        className={cn(bodyStyle, "w-[30%] cursor-pointer")}
                        onClick={() => {
                          handleNavigateCampaigns(l);
                        }}
                        data-testid="campaignClick"
                      >
                        <div
                          className="flex flex-col gap-2.5 justify-between text-left"
                          onClick={() => {
                            handleNavigateCampaigns(l);
                          }}
                        >
                          <div className="flex items-center gap-2">
                            {l?.display_color && (
                              <div
                                className="size-5 rounded-[4px]"
                                style={{
                                  background:
                                    l?.display_color ?? l?.display_color,
                                }}
                              />
                            )}
                            {l?.display_emoji && (
                              <span className="chart-headers">
                                {l?.display_emoji}
                              </span>
                            )}
                            {l?.name?.length > 15 ? (
                              <Tooltip title={l?.name} placement="top">
                                <div className="new-header truncate max-w-[15ch] capitalize">
                                  {l?.name}
                                </div>
                              </Tooltip>
                            ) : (
                              <div className="new-header capitalize">
                                {l?.name}
                              </div>
                            )}

                            {/* <img
                              src={ICONS?.starredUnclicked}
                              alt="starredUnclicked"
                            /> */}
                          </div>
                          <div
                            className="flex items-center gap-1.5"
                            onClick={() => {
                              handleNavigateCampaigns(l);
                            }}
                          >
                            <div className="size-5 rounded-[4px]">
                              <Calendar
                                color={"var(--BG-600)"}
                                width={"22"}
                                height={"21"}
                              />
                            </div>
                            <div className="text-xs">
                              {/* Created @ {date?.monthInShort} {date?.date},{" "}
                              {date?.hours}:{date?.minutes}
                              {date?.meridiem} */}
                              Created @ {date?.monthInShort} {date?.date},{" "}
                              {date?.timeWithMeridiem}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={cn(
                          "new-body-text",
                          "w-[14%] flex items-center gap-1.5"
                        )}
                        style={{
                          color: status?.color,
                        }}
                        onClick={() => {
                          handleNavigateCampaigns(l);
                        }}
                      >
                        <div
                          className="flex-row align-center p-1 pl-10 pr-10 rounded-full gap-1"
                          style={{
                            background: status?.background,
                            border: `1px solid ${status?.border}`,
                          }}
                        >
                          {status?.icon && (
                            <img src={status?.icon} alt={status?.text} />
                          )}
                          {status?.text}
                        </div>
                      </div>
                      <div
                        className={cn("new-body-text", "w-[14%] pl-10")}
                        onClick={() => {
                          handleNavigateCampaigns(l);
                        }}
                      >
                        {/* {l?.channel ?? "--"} */}
                        <img src={ICONS?.whatsApp} alt="whatsApp" />
                      </div>
                      <div
                        className={cn("new-body-text", "w-[17%] pl-5")}
                        onClick={() => {
                          handleNavigateCampaigns(l);
                        }}
                      >
                        {campaign_type_id[l?.campaign_type_id]}
                      </div>
                      <div
                        className={cn("new-body-text", "w-[15%] pl-5")}
                        onClick={() => {
                          handleNavigateCampaigns(l);
                        }}
                      >
                        {campaign_goal_type_id[l?.campaign_goal_type_id] ??
                          "--"}
                      </div>
                      <div
                        className={cn("new-body-text", "w-[15%]")}
                        onClick={() => {
                          handleNavigateCampaigns(l);
                        }}
                      >
                        <div className="w-2/3">
                          <p>
                            {l?.progress === null
                              ? "0"
                              : Math.round(l?.progress)}
                            %
                          </p>
                          <div className="progress-container">
                            <progress
                              value={l?.progress}
                              max="100"
                              className="custom-progress"
                            />
                          </div>
                        </div>
                      </div>
                      {location?.pathname !== "/user/campaign/perpetual" && (
                        <div
                          className={cn(
                            bodyStyle,
                            "w-[10%] flex items-center gap-3"
                          )}
                        >
                          <img
                            src={ICONS?.analytics}
                            alt="analytics"
                            onClick={() => {
                              handleNavigateCampaigns(l);
                            }}
                            className="hover:bg-gray-100 p-10 b-radius-6"
                          />
                          <Popover
                            content={
                              <>
                                {l?.status !== 12 && (
                                  <div className="flex-column p-[15px] mb-3.5">
                                    {(l?.status === 11 || l?.status === 10) && (
                                      <div
                                        className={`campaign-edit-test flex-row align-center m-5 pointer ${
                                          l?.status !== 11 && l?.status !== 12
                                            ? "tags-border pb-10"
                                            : ""
                                        }`}
                                        onClick={(e) =>
                                          handleEditCampaign(e, l)
                                        }
                                      >
                                        <img
                                          src={ICONS?.crmTagEdit}
                                          alt=""
                                          className=""
                                        />
                                        <span className="pl-10 text-[0.8vw] text-[var(--contentText)] font-500">
                                          Edit
                                        </span>
                                      </div>
                                    )}
                                    {l?.status === 10 && (
                                      <div
                                        className="campaign-delete-test flex-row align-center pr-10  pointer"
                                        onClick={(e) =>
                                          handleDeleteCampaign(e, l)
                                        }
                                      >
                                        <img
                                          src={ICONS?.RedDeleteIcon}
                                          alt=""
                                          className="mt-10"
                                        />
                                        <span className="text-[0.8vw] text-[--fontRed] font-500 mt-10">
                                          Delete
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            }
                            trigger="click"
                            arrow={false}
                            open={startPopoverVisible[l?.id] || false}
                            onOpenChange={() => togglePopoverVisibility(l?.id)}
                            placement="bottom"
                          >
                            <div
                              className={`dots group/dots w-fit min-w-11 pl-10 py-4 flex items-center gap-0.5 hover:bg-gray-100 rounded-md ${
                                l?.status === 12
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              }`}
                            >
                              <span
                                className={`${dotStyle} ${
                                  l?.status === 12
                                    ? "bg-[#E8E8EA]"
                                    : "bg-[var(--BG-200)]"
                                }`}
                              ></span>
                              <span
                                className={`${dotStyle} ${
                                  l?.status === 12
                                    ? "bg-[#E8E8EA]"
                                    : "bg-[var(--BG-200)]"
                                }`}
                              ></span>
                              <span
                                className={`${dotStyle} ${
                                  l?.status === 12
                                    ? "bg-[#E8E8EA]"
                                    : "bg-[var(--BG-200)]"
                                }`}
                              ></span>
                            </div>
                          </Popover>
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {!searchKey && !selectedTypes && !statusTrue ? (
                  <div className="w-full h-100 flex justify-center campaign-no-data">
                    <NoData
                      title="No Campaigns yet?"
                      content="Go ahead, create your first campaign to start engaging with your visitors and turn them into customers!"
                      modal={
                        <div>
                          {selectedTypes &&
                            !selectedTypes.includes(3) &&
                            location?.pathname !==
                              `/user/campaign/perpetual` && (
                              <button
                                className="flex-row align-center px-4 py-1.5 text-base weight-medium rounded-md bg-[var(--new-primary-bg)] border-2 border-[var(--new-primary)] text-[var(--new-primary)]"
                                onClick={() => onCreate(true)}
                              >
                                <img
                                  src={ICONS?.CreateCampaign}
                                  alt="Create"
                                  className="pr-5"
                                />
                                Create Campaign
                              </button>
                            )}
                        </div>
                      }
                      icon={ICONS?.campaignNoData}
                    />
                  </div>
                ) : (
                  <div className="h-[60vh] flex justify-center mt-20 pt-20">
                    <div className="flex-row h-10 mt-20">
                      <img
                        src={ICONS?.emptySearch}
                        alt="emptySearch"
                        className="w-14"
                      />
                      <div className="flex-column gap-3 pl-10">
                        <span className="new-header3 ">
                          Sorry, there are no matching results
                        </span>
                        <span className="no-search-body">
                          Use different filters or{" "}
                          <span
                            className="text-[#6940F2] pointer"
                            onClick={() => {
                              setSearchKey("");
                              clearType();
                              clearStatus();
                            }}
                          >
                            reset to default.
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </InfiniteScroll>
        </div>
      </Skeleton>

      {isModalOpen && (
        <CreateCampaignModal
          type="edit"
          open={isModalOpen}
          setOpen={setIsModalOpen}
          data={selectedCampaign}
        />
      )}
      <Modal
        footer={null}
        open={discardAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        <NewAlert
          type="new"
          open={discardAlertOpen}
          icon={ICONS?.discardCarousel}
          title={"Delete Campaign?"}
          setOpen={setDiscardAlertOpen}
          content={
            <p>
              Are you sure you want to delete this <b>"{campaignName}"</b> ?
            </p>
          }
          noName="Yes,Delete"
          yesName="No"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => setDiscardAlertOpen(false)}
          handleYes={() => {
            deleteCampaign(campaignId);
          }}
          loading={deleteLoader}
        />
      </Modal>
    </div>
  );
}
