import React from "react";

// helper
import { dateConverter } from "../../../../helper/dateConverter";
import { replaceFormating } from "../../helper";
import ImageMessagePreview from "./ImageMessagePreview";
import VideoMessagePreview from "./VideoMessagePreview";
import DocumentMessagePreview from "./DocumentMessagePreview";
import AudioMessagePreview from "./AudioMessagePreview";

export default function LeftSideConversationCard({ data }) {
  const d = dateConverter(data?.created_at, false);

  const parseObj = (obj) => {
    if (typeof obj === "string") return JSON.parse(obj);
    return "";
  };

  return (
    <div className="w-full min-h-[3.5vh] h-full">
      <div className="h-fit relative w-fit max-w-[30vw]">
        <div className="w-0 h-0 bg-transparent border-l-[0.5vw] border-r-[0.5vw] border-b-[1vw] border-l-transparent border-r-transparent border-b-[#F4F6F8] absolute -left-[0.5vw] top-0 rotate-180" />

        {["text", "image", "video", "document", "audio"].includes(
          data?.message?.type
        ) ? (
          data?.message?.type === "image" ? (
            <ImageMessagePreview
              url={data?.message?.image?.link}
              caption={data?.message?.image?.caption}
            />
          ) : data?.message?.type === "video" ? (
            <VideoMessagePreview
              url={data?.message?.video?.link}
              caption={data?.message?.video?.caption}
            />
          ) : data?.message?.type === "document" ? (
            <DocumentMessagePreview
              url={data?.message?.document?.link}
              caption={data?.message?.document?.caption}
              fileName={data?.message?.document?.filename}
            />
          ) : data?.message?.type === "audio" ? (
            <AudioMessagePreview
              url={data?.message?.audio?.link}
              caption={data?.message?.audio?.caption}
            />
          ) : (
            <div
              className={`w-full h-full font-md weight-medium text-[var(--contentText)] bg-[#F4F6F8] rounded-[0.6vw] rounded-tl-none p-[0.5vw] py-[0.5vw]`}
              dangerouslySetInnerHTML={{
                __html: replaceFormating(data?.message?.text?.body),
              }}
            ></div>
          )
        ) : (
          <div className="bg-[#F4F6F8] p-[0.5vw] rounded-[0.6vw] rounded-tl-none">
            {data?.is_customer_msg === true &&
            Array.isArray(data?.message?.template || data?.message) ? (
              <>
                <ConversationCard data={data} />
              </>
            ) : (
              (data?.message?.text?.body || data?.message?.body) && (
                <div
                  className={`w-full font-md weight-medium text-[var(--contentText)] rounded-[0.6vw] rounded-tl-none py-[0vw]`}
                  dangerouslySetInnerHTML={{
                    __html: replaceFormating(
                      data?.message?.text?.body || data?.message?.body
                    ),
                  }}
                >
                  {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
                  {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
                </div>
              )
            )}
          </div>
        )}
      </div>
      <div className="text-[0.55vw] weight-semibold text-[var(--font-400)] mt-[0.3vw]">
        <p>{d?.timeWithMeridiem}</p>
      </div>
    </div>
  );
}
