import React from "react";
import { addLineBreake, replaceFormating } from "../../helper";

export default function AudioMessagePreview({ url, caption }) {
  return (
    <div className="flex flex-col gap-2.5">
      <div className="w-[18vw] h-full">
        <audio className=" object-cover rounded-[0.4vw]" controls>
          <source src={url} type="audio/mp3" /> Your browser does not support
          the audio element.
        </audio>
      </div>
      <p
        className="font-md font-light px-[0.5vw] pt-[0.5vw] break-words"
        dangerouslySetInnerHTML={{
          __html: addLineBreake(replaceFormating(caption)),
        }}
      ></p>
    </div>
  );
}
