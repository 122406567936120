import { ICONS } from "../../../assets/icons/index";
import { images } from "assets/images";

export const TYPES = [
  {
    id: 1,
    icon: "",
    label: "Broadcast",
    key: "broadcast",
    isSelected: false,
  },
  {
    id: 2,
    icon: "",
    label: "Sequence",
    key: "sequence",
    isSelected: false,
  },
];

export const STATUS = [
  {
    id: 1,
    icon: ICONS?.draft,
    label: "Draft",
    key: "draft",
    status: 0,
    isSelected: false,
    color: "#8B5CF6",
    background: "var(--opacityBackground1)",
  },
  {
    id: 2,
    icon: ICONS?.scheduled,
    label: "Scheduled",
    key: "scheduled",
    status: 10,
    isSelected: false,
    background: "var(--primaryOpacity)",
    border: "var(--secondary)",
  },
  // {
  //   id: 3,
  //   icon: ICONS?.activeIcon,
  //   label: "Active",
  //   key: "active",
  //   status: 10,
  //   isSelected: false,
  //   background: "var(--primaryOpacity)",
  //   border: "var(--secondary)",
  // },
  // {
  //   id: 4,
  //   icon: '',
  //   label: 'In-active',
  //   key: 'in-active',
  //   status: 10,
  //   isSelected: false,
  // },
  {
    id: 5,
    icon: ICONS?.runningCampaign,
    label: "Running",
    key: "running",
    status: 11,
    isSelected: false,
    background: "var(--opacityBackground3)",
    border: "var(--primary)",
  },
  {
    id: 6,
    icon: ICONS?.completed,
    label: "Completed",
    key: "completed",
    status: 12,
    isSelected: false,
    background: "var(--opacityBackground2)",
    border: "var(--reviewStatus)",
  },
];

export const SORT = [
  {
    id: 1,
    icon: "",
    label: "Newest first",
    key: "newest",
    isSelected: false,
  },
  {
    id: 2,
    icon: "",
    label: "Oldest first",
    key: "oldest",
    isSelected: false,
  },
  {
    id: 3,
    icon: "",
    label: "Ascending",
    key: "ascending",
    isSelected: false,
  },
  {
    id: 4,
    icon: "",
    label: "Descending",
    key: "descending",
    isSelected: false,
  },
  {
    id: 5,
    icon: "",
    label: "Starred",
    key: "starred",
    isSelected: false,
  },
];

export const CREATION_TYPES = [
  {
    id: 1,
    label: "Create New Broadcast",
    content:
      "Deliver targeted messages to multiple recipients at scheduled times.",
    key: "broadcast",
    icon: ICONS?.broadcastCreate,
    // link: "",
    disabled: false,
  },
  {
    id: 2,
    label: "Create Seamless Flow Sequence",
    content:
      "Trigger automated sequences from customer actions to drive conversions.",
    key: "sequence",
    icon: ICONS?.sequenceCreate,
    // link: "",
    disabled: true,
  },
];
