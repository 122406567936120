import React, { useEffect, useState } from "react";
import "../styles/overview.css";

import { useOutletContext } from "react-router-dom";
import { ICONS } from "../../../assets/icons";

import OverviewChart from "../components/overviewChart/BroadcastPerformance";
import Performance from "../components/overviewChart/Performance";
import {
  overviewData,
  performanceData,
  performanceLeads,
} from "../constants/overview";
import GaugeCharts from "../components/overviewChart/GaugeCharts";
import AreaChart from "../components/overviewChart/AreaChart";
import GlobalLoader from "../../../components/commonComponents/Loader/globalLoader";
import { Tooltip } from "antd";

const Overview = () => {
  const {
    performanceList,
    OverviewList,
    goalInfo,
    goalInfoDate,
    campaignOverviewData,
    handlePerformance,
    filter,
    captureRef,
    loader,
  } = useOutletContext();

  const performanceDataMapping =
    campaignOverviewData?.campaign_goal_type_id === 2
      ? performanceLeads
      : performanceData;

  const [updatedoverviewData, setUpdatedoverviewData] = useState(overviewData);
  const [updatedPerformanceData, setUpdatedPerformanceData] = useState(
    performanceDataMapping
  );

  useEffect(() => {
    if (OverviewList) {
      setUpdatedoverviewData(
        overviewData?.map((item) => {
          switch (item.label) {
            case "Delivered":
              return {
                ...item,
                count: OverviewList?.DELIVERED,
                data: OverviewList?.delivered,
              };
            case "Bounced":
              return {
                ...item,
                count: OverviewList?.FAILED,
                data: OverviewList?.bounced,
              };
            case "Read":
              return {
                ...item,
                count: OverviewList?.READ,
                data: OverviewList?.read,
              };
            case "Sent":
              return {
                ...item,
                count: OverviewList?.SENT,
                data: OverviewList?.sent,
              };
            default:
              return item;
          }
        })
      );
    }

    if (performanceDataMapping) {
      setUpdatedPerformanceData(
        performanceDataMapping?.map((item) => {
          switch (item.label) {
            case "Clicks":
              return { ...item, count: performanceList?.CLICKED };
            case "Reactions":
              return { ...item, count: performanceList?.REACTED };
            case "Leads":
              return { ...item, count: performanceList?.REPLIED };
            case "Blocked":
              return { ...item, count: performanceList?.BLOCKED };
            default:
              return item;
          }
        })
      );
    }
  }, [OverviewList, performanceList]);

  // const goalInfoRoundof =
  //   goalInfo?.metric_type === "NUMBER"
  //     ? goalInfo?.goal_target < goalInfo?.goal_achieved
  //     : goalInfo?.goal_target - goalInfo?.goal_achieved;

  // const goalRound =
  //   goalInfo?.metric_type === "NUMBER" ? 0 : `${Math.round(goalInfoRoundof)} %`;

  const goalTarget = goalInfo?.goal_target || 0;
  const goalAchieved = goalInfo?.goal_achieved || 0;

  const goalDifference = goalTarget - goalAchieved;
  const goalRound2 = goalDifference < 0 ? 0 : goalDifference;

  const goalRound =
    goalInfo?.metric_type === "NUMBER"
      ? goalRound2
      : `${Math.round(goalRound2)} %`;

  const formatCount = (count) => {
    if (count >= 1000) {
      return `${Math.floor(count / 1000)}K`;
    }
    return count;
  };

  return (
    <div className={`h-full max-h-[65vh]  overflow-y-scroll listScroll`}>
      <div className="flex flex-col gap-7 overflow-hidden" ref={captureRef}>
        <div className="flex-row align-center gap-7">
          <div className="grid grid-cols-2 w-[25%] h-auto min-h-[44vh] gap-7">
            {updatedoverviewData?.map((item) => (
              <div
                className="w-100 bg-white rounded-2xl flex flex-col pt-10 prl-10 ml-10 justify-between"
                key={item?.id}
              >
                {loader ? (
                  <div className="w-20 h-[0vh] flex align-center justify-center">
                    <GlobalLoader />
                  </div>
                ) : (
                  <>
                    <div className="">
                      <div
                        className="flex-row align-center  gap-2 no-search-body"
                        style={{ transform: "translate(0px, 15px)" }}
                      >
                        <img src={item?.icon} alt="" className="w-20" />
                        {item?.label}
                        <Tooltip title={`Overall ${item?.label} Count`}>
                          <img
                            src={ICONS?.conversationAction}
                            alt="performanceIcon"
                            className="pointer"
                          />
                        </Tooltip>
                      </div>
                      <div
                        className={` ${
                          item?.count === undefined
                            ? "main-header-light"
                            : "main-header"
                        }`}
                        style={{ transform: "translate(0px, 20px)" }}
                      >
                        {formatCount(item?.count || 0)}
                      </div>
                    </div>
                    <AreaChart
                      data={item?.data}
                      color={item.color}
                      name={item?.label}
                    />
                  </>
                )}

                {/* <AreaChart OverviewList={OverviewList} /> */}
              </div>
            ))}
          </div>
          <div className="w-50 h-auto min-h-[44vh] bg-white rounded-2xl flex flex-col ">
            {loader ? (
              <div className="w-[68%] h-[20vh] flex align-center justify-center">
                <GlobalLoader />
              </div>
            ) : (
              <>
                <Performance performanceList={performanceList} />

                <div className="flex-row align-center text-[var(--textBlack)] mt-10 pt-5">
                  {updatedPerformanceData?.map((item) => (
                    <div
                      key={item?.id}
                      className={`w-full flex-col items-center gap-[1.3vw] border-r-[1px] last:border-r-0 ${
                        item?.label !== "Conversions"
                          ? "pointer"
                          : "cursor-not-allowed"
                      }`}
                      onClick={() => {
                        item?.label !== "Conversions" &&
                          handlePerformance(item?.value);
                      }}
                      data-testId="performanceClick"
                    >
                      <div
                        className={`rounded-lg w-100 ${
                          filter?.includes(item?.value) &&
                          item?.label !== "Conversions"
                            ? "bg-[#E8E8EA] w-95 m-5 p-5"
                            : ""
                        }`}
                      >
                        <div className="flex-row justify-center items-center">
                          <img src={item?.icon} alt="summary" className="w-5" />
                          <span className="no-search-body px-2">
                            {item?.label}
                          </span>
                          <Tooltip title={`Overall ${item?.label} Count`}>
                            <img
                              src={ICONS?.conversationAction}
                              alt="performanceIcon"
                              className=""
                            />
                          </Tooltip>
                        </div>
                        <div className="flex-row justify-center pt-5">
                          <span
                            className={` ${
                              item?.count === undefined
                                ? "count-style-light"
                                : "count-style"
                            }`}
                          >
                            {item?.count || 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="w-30 h-auto min-h-[44vh] bg-white rounded-2xl flex flex-col py-4">
            <div className="flex-row align-center">
              <span className="chart-headers pl-20">Goal Info</span>
              <Tooltip title={`Unique Counts`}>
                <img
                  src={ICONS?.conversationAction}
                  alt="performanceIcon"
                  className="pointer pl-10"
                />
              </Tooltip>
            </div>
            <span className="w-100 border-b mt-5 mb-20"></span>
            {loader ? (
              <div className="w-20 h-[0vh] flex align-center justify-center">
                <GlobalLoader />
              </div>
            ) : (
              <div className="flex-column space-between h-100 ">
                <GaugeCharts
                  id="gauge-chart1"
                  goalInfo={goalInfo}
                  campaignOverviewData={campaignOverviewData}
                />
                <div className="mt-20 ml-10 w-90 h-100 flex-row space-between">
                  <div className="flex-column align-center">
                    <span
                      className={`ml-20 pt-20 flex-row align-center gap-2 ${
                        goalRound === 0 ? "main-header-light" : "main-header"
                      }`}
                    >
                      <img
                        src={ICONS?.goalTargetInfo}
                        alt="goalTargetInfo"
                        className=""
                      />
                      {goalRound}
                    </span>
                    <span className="ml-20 new-sidebar">Targets remaining</span>
                  </div>

                  <div className="flex-column align-center">
                    <span
                      className={`ml-20 pt-20 flex-row align-center gap-2 ${
                        goalInfo?.progress_status === "No data found"
                          ? "main-header-light"
                          : "main-header"
                      }`}
                    >
                      <img
                        src={ICONS?.goalRemainingDays}
                        alt="goalTargetInfo"
                        className="w-60"
                      />
                      {goalInfo?.progress_status === "No data found"
                        ? 0
                        : goalInfoDate}
                    </span>
                    <span className="ml-20 new-sidebar">Days remaining</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-100 h-100 bg-white rounded-2xl flex flex-col ptb-10">
          <OverviewChart />
        </div>
      </div>
    </div>
  );
};

export default Overview;
