import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Popover } from "antd";

// assets
import ClearFilters from "../../../../assets/customSVG/ClearFilters";

// helper
import { cn } from "helper/cn";

// hooks
import useToggle from "hooks/useToggle";

// constants
import { CAMPAIGN_TYPE, goalTypes, STATUS } from "../../constants/campaignList";

// components
import List from "./List";
import SearchBar from "../../../../components/commonComponents/searchBar";
import CheckBoxSqure from "../../../../components/commonComponents/checkbox/CheckBoxSqure";
import RadioButton from "../../../../components/form/radio/RadioButton";

export default function CampaignsListContainer(props) {
  const {
    list,
    count,
    isLoading,
    searchKey,
    setSearchKey,
    onSearchChange,
    sort,
    onSortChange,
    type,
    onTypeChange,
    clearType,
    selectedTypes,
    status,
    onStatusChange,
    clearStatus,
    loadNextPage,
    sortLabel,
    isLoadingSkeleton,
    onCreate,
    isModalOpen,
    setIsModalOpen,
    statusTrue,
  } = props;

  const { goalType } = useParams();

  const [selectAll, setSelectAll] = useToggle(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [intermediate, setIntermediate] = useToggle(false);

  const [selectedTypeIds, setselectedTypeIds] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState([]);
  const [selectedSortId, setSelectedSortId] = useState([]);

  useEffect(() => {
    setSelectAll(checkAllRowsAreSelected());
    setIntermediate(
      !checkAllRowsAreSelected() && selectedRows?.length > 0 ? true : false
    );
  }, [selectedRows]);

  useEffect(() => {
    if (selectAll && list.length > 0) setSelectedRows(list?.map((l) => l.id));
  }, [selectAll, list]);

  useEffect(() => {
    getSelectedGoalTypesId();
  }, [type]);

  useEffect(() => {
    getSelectedStatusId();
  }, [status]);

  useEffect(() => {
    getSelectedSortId();
  }, [sort]);

  const checkAllRowsAreSelected = useCallback(() => {
    const res = list?.map((l) => isRowSelected(l?.id));
    const check = res?.filter((r) => !r);
    return check?.length === 0 ? true : false;
  }, [selectedRows, list]);

  const isRowSelected = useCallback(
    (id) => selectedRows.includes(id),
    [selectedRows]
  );

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const res = list.map((l) => l?.id);
      setSelectedRows(res);
    }
    setSelectAll((prev) => !prev);
  };

  const onRowSelect = useCallback(
    (id) => {
      if (isRowSelected(id)) {
        const res = selectedRows.filter((r) => r !== id);
        setSelectedRows(res);
      } else {
        setSelectedRows([...selectedRows, id]);
      }
    },
    [selectedRows]
  );

  const getSelectedGoalTypesId = useCallback(
    () => setselectedTypeIds(type?.filter((t) => t?.isSelected)),
    [type]
  );

  const getSelectedStatusId = useCallback(
    () => setSelectedStatusId(status?.filter((t) => t?.isSelected)),
    [status]
  );

  const getSelectedSortId = useCallback(
    () => setSelectedSortId(sort?.filter((t) => t?.isSelected)),
    [sort]
  );

  const allowAllClear = useCallback(() => {
    return selectedTypeIds?.length > 0 || selectedStatusId?.length > 0;
  }, [selectedTypeIds, selectedStatusId]);

  return (
    <div className="w-full h-full bg-white rounded-t-2xl px-5">
      <div className="w-full py-5 flex items-center justify-between gap-5 border-b border-[var(--border-50)]">
        <h4 className="new-header3">
          {selectedRows?.length > 0
            ? `${selectedRows?.length} Rows Selected`
            : `${count || 0} Existing campaigns`}
        </h4>
        <div className="flex items-center gap-5">
          <div>
            <SearchBar
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              onChange={onSearchChange}
              loading={false}
              expandable={true}
            />
          </div>
          <Popover
            trigger={["click"]}
            placement="bottom"
            arrow={false}
            content={
              <div className="min-w-56 px-3.5 py-2.5">
                {sort?.map((section) => (
                  <div key={section?.heading}>
                    <h3 className="new-header1 mb-2 pt-5">{section.heading}</h3>
                    {section?.options?.map((s) => (
                      <div
                        key={s.id}
                        className={cn(
                          "w-full h-9 flex items-center gap-2.5 py-1 ",
                          !["all-campaigns"]?.includes(goalType) &&
                            !s?.isSelected
                            ? "cursor-not-allowed opacity-50"
                            : "cursor-pointer opacity-100"
                        )}
                        onClick={() => {
                          if (["all-campaigns"]?.includes(goalType)) {
                            onSortChange(section?.id, s);
                          }
                        }}
                      >
                        <div className="w-5 h-9 flex items-center justify-center mt-1">
                          <RadioButton
                            value={s?.isSelected}
                            selected={s?.isSelected}
                            onChange={(e) => {}}
                            rootClassName={
                              s?.isSelected && "size-3.5 new-radio"
                            }
                          />
                        </div>
                        {s?.icon && <img src={s?.icon} alt={s.label} />}
                        <p>{s?.label}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            }
          >
            <div className="group/sort flex items-center gap-2.5 bg-[var(--BG-25)] px-3 py-1.5 rounded-[0.4vw] cursor-pointer">
              <div className="campaign-filters">
                {sortLabel || "Newest First"}
              </div>
              <div
                className={cn(
                  "transition-transform duration-200 ease-linear",
                  // group-hover/sort:-rotate-180,
                  false ? "-rotate-180" : "rotate-0",
                  isLoading ? "cursor-not-allowed" : "cursor-pointer"
                )}
              >
                <div
                  className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                ></div>
              </div>
            </div>
          </Popover>
          {/* <Popover
            trigger={["click"]}
            arrow={false}
            placement="bottom"
            content={
              <div className="min-w-56 px-3.5 py-2.5">
                <div className="w-full h-8 flex items-center justify-between gap-5 py-2.5 text-sm weight-medium">
                  <div className="text-[var(--font-400)]">Goal Type</div>
                  <div
                    className={cn(
                      "text-[#F96056]",
                      selectedTypeIds?.length > 0
                        ? "cursor-pointer opacity-100"
                        : "cursor-not-allowed opacity-50"
                    )}
                    onClick={() => {
                      if (selectedTypeIds?.length > 0) clearGoalType();
                    }}
                  >
                    Clear
                  </div>
                </div>
                {type?.map((g) => {
                  return (
                    <div
                      className={cn(
                        "w-full h-9 flex items-center gap-2.5 py-1",
                        !["all-campaigns"]?.includes(goalType) && !g?.isSelected
                          ? "cursor-not-allowed opacity-50"
                          : "cursor-pointer opacity-100"
                      )}
                      onClick={() => {
                        if (["all-campaigns"]?.includes(goalType)) {
                          onGoalTypeChange(g);
                        }
                      }}
                    >
                      <CheckBoxSqure
                        // value={selectedTypeIds?.includes(g?.id) ? true : false}
                        value={g?.isSelected}
                        onChange={(e) => {}}
                        className="size-4"
                      />
                      {g?.icon && <img src={g?.icon} alt={g.label} />}
                      <p>{g?.label}</p>
                    </div>
                  );
                })}
              </div>
            }
          >
            <div className="group/goal flex items-center gap-2.5 bg-[var(--BG-50)] px-3 py-1.5 rounded-[0.4vw] cursor-pointer">
              <div>
                Goal Type (
                {selectedTypeIds?.length === goalTypes?.length ||
                selectedTypeIds?.length === 0
                  ? "All"
                  : ["all-campaigns"]?.includes(goalType) &&
                    selectedTypeIds?.length > 0 &&
                    selectedTypeIds?.length !== goalTypes?.length
                  ? selectedTypeIds?.length
                  : type?.filter((t) => t?.isSelected)?.[0]?.label}
                )
              </div>
              <div
                className={cn(
                  "transition-transform duration-200 ease-linear",
                  false ? "-rotate-180" : "rotate-0",
                  isLoading ? "cursor-not-allowed" : "cursor-pointer"
                )}
              >
                <div
                  className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                ></div>
              </div>
            </div>
          </Popover> */}
          {location?.pathname !== "/user/campaign/perpetual" && (
            <Popover
              trigger={["click"]}
              arrow={false}
              placement="bottom"
              content={
                <div className="min-w-56 px-3.5 py-2.5">
                  <div className="w-full h-8 flex items-center justify-between gap-5 py-2.5 text-sm weight-medium">
                    <div className="text-[var(--font-400)]">Campaign type</div>
                    <div
                      className={cn(
                        "text-[#F96056]",
                        selectedTypeIds?.length > 0
                          ? "cursor-pointer opacity-100"
                          : "cursor-not-allowed opacity-50"
                      )}
                      onClick={() => {
                        if (selectedTypeIds?.length > 0) clearType();
                      }}
                    >
                      Clear
                    </div>
                  </div>
                  {type?.map((s) => {
                    return (
                      <div
                        className={cn(
                          "w-full h-9 flex items-center gap-2.5 py-1 cursor-pointer"
                        )}
                        onClick={() => {
                          onTypeChange(s);
                        }}
                        key={s?.id}
                      >
                        <CheckBoxSqure
                          value={s?.isSelected}
                          onChange={(e) => {}}
                          className="size-4"
                        />
                        {s?.icon && (
                          <img src={s?.icon} alt={s.label} className="w-4" />
                        )}
                        <p>{s?.label}</p>
                      </div>
                    );
                  })}
                </div>
              }
            >
              <div className="group/goal flex items-center gap-2.5 bg-[var(--BG-25)] px-3 py-1.5 rounded-[0.4vw] cursor-pointer">
                <div className="campaign-filters">
                  Campaign type (
                  {selectedTypeIds?.length > 0 === STATUS?.length ||
                  selectedTypeIds?.length === 0
                    ? "All"
                    : selectedTypeIds?.length}
                  )
                </div>
                <div
                  className={cn(
                    "transition-transform duration-200 ease-linear",
                    false ? "-rotate-180" : "rotate-0",
                    isLoading ? "cursor-not-allowed" : "cursor-pointer"
                  )}
                >
                  <div
                    className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                  ></div>
                </div>
              </div>
            </Popover>
          )}
          <Popover
            trigger={["click"]}
            arrow={false}
            placement="bottom"
            content={
              <div className="min-w-56 px-3.5 py-2.5">
                <div className="w-full h-8 flex items-center justify-between gap-5 py-2.5 text-sm weight-medium">
                  <div className="text-[var(--font-400)]">Status</div>
                  <div
                    className={cn(
                      "text-[#F96056]",
                      selectedStatusId?.length > 0
                        ? "cursor-pointer opacity-100"
                        : "cursor-not-allowed opacity-50"
                    )}
                    onClick={() => {
                      if (selectedStatusId?.length > 0) clearStatus();
                    }}
                  >
                    Clear
                  </div>
                </div>
                {status?.map((s) => {
                  return (
                    <div
                      className={cn(
                        "w-full h-9 flex items-center gap-2.5 py-1 cursor-pointer"
                      )}
                      onClick={() => {
                        onStatusChange(s);
                      }}
                      key={s?.id}
                    >
                      <CheckBoxSqure
                        value={s?.isSelected}
                        onChange={(e) => {}}
                        className="size-4"
                      />
                      {s?.icon && (
                        <img src={s?.icon} alt={s.label} className="w-4" />
                      )}
                      <p>{s?.label}</p>
                    </div>
                  );
                })}
              </div>
            }
          >
            <div className="group/goal flex items-center gap-2.5 bg-[var(--BG-25)] px-5 py-1.5 rounded-[0.4vw] cursor-pointer">
              <div className="campaign-filters">
                Status (
                {selectedStatusId?.length === STATUS?.length ||
                selectedStatusId?.length === 0
                  ? "All"
                  : selectedStatusId?.length}
                )
              </div>
              <div
                className={cn(
                  "transition-transform duration-200 ease-linear",
                  false ? "-rotate-180" : "rotate-0",
                  isLoading ? "cursor-not-allowed" : "cursor-pointer"
                )}
              >
                <div
                  className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                ></div>
              </div>
            </div>
          </Popover>
          <div
            className={cn(
              "group/status flex items-center gap-2.5 hover:bg-[var(--BG-25)] px-2.5 py-2.5 rounded-[0.4vw]",
              allowAllClear()
                ? "cursor-pointer opacity-100"
                : "cursor-not-allowed opacity-50"
            )}
            onClick={() => {
              if (allowAllClear()) {
                clearType();
                clearStatus();
              }
            }}
          >
            <ClearFilters
              width={16}
              height={16}
              color={allowAllClear() ? "var(--contentText)" : "var(--font-600)"}
            />
          </div>
        </div>
      </div>
      <List
        isLoading={isLoading}
        list={list}
        count={count}
        loadNextPage={loadNextPage}
        isLoadingSkeleton={isLoadingSkeleton}
        searchKey={searchKey}
        onCreate={() => onCreate(true)}
        selectedTypes={selectedTypes}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setSearchKey={setSearchKey}
        selectedStatusId={selectedStatusId}
        clearType={clearType}
        clearStatus={clearStatus}
        statusTrue={statusTrue}
      />
    </div>
  );
}
